$section-page-margin-top: 36px;
$section-page-margin-top-with-back: 24px;

.settings-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  &.settings_loading {
    align-content: center;
    height: calc(100vh - #{$masthead-height});
  }

  .settings-back {
    display: inline-flex;
    align-items: center;
    align-self: baseline;
    margin-bottom: 12px;

    span {
      margin-left: 6px;
      font-size: 14px;
      font-weight: 700;
    }
  }

  .settings_no-user {
    @extend %flex-center;
    flex-direction: column;
    height: 400px;
    width: 100%;
    border: 1px solid var(--border-color-grey);
    background-color: var(--border-color-grey);

    img {
      width: 300px;
    }

    .error-msg {
      margin-top: 24px;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .settings-header {
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 12px;

    h1 {
      margin: 0;
      color: #ffffff;
      font-size: 36px;
      font-weight: 700;
    }

    h2 {
      margin: 0;
      margin-left: 12px;
      color: #cddcfe;
      font-size: 36px;
    }
  }

  .settings-nav {
    margin-right: 12px;
    width: 245px;

    &.notification-open {
      margin-top: 53px;
    }

    .header-title {
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: bold;
    }

    .settings-links-container {
      display: flex;
      flex-direction: column;
      // border: 1px solid $ugg-section-blue;
      border: 1px solid var(--border-color-grey);
      box-shadow: var(--ugg-box-shadow);

      .settings-nav-link {
        padding-top: 14px;
        padding-bottom: 14px;
        padding-left: 20px;
        height: 44px;
        background-color: var(--ugg-background-alt-2);
        font-size: 12px;
        font-weight: bold;

        &:not(:last-child) {
          // border-bottom: 1px solid $ugg-section-blue;
          border-bottom: 1px solid var(--border-color-grey);
        }

        &:hover,
        &.active {
          // background-color: $ugg-section-blue;
          background-color: var(--border-color-grey);
        }
      }
    }
  }

  .settings-router {
    // margin-top: 36px;
    min-width: 1014px;

    .settings-notification {
      flex: 0 0 760px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
      margin-bottom: -12px;
      padding: 0 12px;
      height: 36px;
      background-color: $ugg-light-green;

      .notification-msg {
        color: #ffffff;
        font-size: 12px;
        font-weight: bold;
      }

      .close-icon {
        padding: 2px;
        width: 15px;

        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }
    }

    .submit-success {
      font-size: 16px;
      margin-bottom: 24px;
      font-weight: 700;
      color: #32cd32;
    }
    .submit-error {
      @extend %error-dialogue-box;
      margin-bottom: 24px;
    }

    .settings-route-page {
      margin-top: $section-page-margin-top;

      .header-title {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: bold;
      }

      .settings-section {
        &:not(:last-child) {
          margin-bottom: 12px;
        }

        .section-header {
          display: flex;
          align-items: center;
          margin-bottom: 6px;
          padding-left: 20px;
          padding-right: 20px;
          height: 46px;
          border: 1px solid var(--border-color-grey);
          background-color: var(--ugg-background-alt);
          box-shadow: var(--ugg-box-shadow);
          font-size: 12px;
          font-weight: bold;
        }

        .section-content {
          padding: 20px;
          border: 1px solid var(--border-color-grey);
          background-color: var(--ugg-background-alt-2);
          box-shadow: var(--ugg-box-shadow);
          font-size: 12px;

          .section-content_label {
            margin-bottom: 12px;
            font-size: 14px;
            font-weight: bold;
            // margin-bottom: 5px;
            // font-size:14px;
            // font-weight: bold;
          }

          .section-content_block {
            background-color: #17172e;
            padding: 20px;
          }
        }
      }

      .field-group {
        &:not(:last-child) {
          margin-bottom: 24px;
        }
        .field-label {
          margin-bottom: 10px;
          font-size: 14px;
          font-weight: bold;

          &._with-helper-text {
            margin-bottom: 7px;
          }
        }
      }

      .save-settings-btn {
        width: 120px;
        .btn-loader {
          width: 20px;
        }
      }
    }

    .settings_my-account {
      .personal-information {
        .field-group {
          max-width: 356px;
        }

        .name {
          display: flex;
          align-items: flex-end;
          margin-bottom: 20px;

          .field-group {
            margin-bottom: 0;
          }
          .field-group:first-child {
            margin-right: 12px;
          }
        }

        .dob {
          .dob-form {
            width: 270px;
          }
        }

        .summoner-name-verification-box {
          display: flex;
        }

        .verify-button {
          margin-left: 12px;
          width: 200px;
          height: 30px;
        }

        .verified-indicator {
          display: flex;
          align-items: center;
          height: 35px;
          .verified-img {
            width: 18px;
            height: 20px;
            margin-right: 6px;
            margin-left: 12px;
          }

          .verified-text {
            font-size: 12px;
            font-weight: 700;
          }
        }
      }

      .change-password {
        .field-group {
          max-width: 356px;
        }
      }

      .delete-account {
        .deletion-info {
          font-size: 14px;

          .deletion-info_header {
            font-weight: bold;
          }

          .deletion-info_list {
            margin: 14px 0 24px;
            padding-left: 14px;
            color: #cddcfe;
          }
        }

        .delete-account-btn {
          width: 147px;
        }

        .error-msg {
          margin-bottom: 5px;
          color: $ugg-red;
          font-size: 12px;
          font-weight: bold;
        }

        .delete-account-form {
          max-width: 356px;

          .confirmation-btns {
            display: flex;
            margin-top: 20px;

            .confirm-btn {
              margin-right: 12px;
            }

            .cancel-btn {
            }
          }
        }
      }
    }

    .settings_helper_text {
      color: #cddcfe;
      font-size: 14px;
    }

    .settings-filter-container {
      display: flex;
      margin-top: 17px;
      // width: 120px;
    }

    .settings_notifications {
    }

    .settings_change-card {
      margin-top: $section-page-margin-top-with-back;
    }

    .settings_change-plan {
      margin-top: $section-page-margin-top-with-back;
    }

    .settings_confirm-plan {
      margin-top: $section-page-margin-top-with-back;

      .confirm-plan {
        display: flex;
        flex-direction: column;

        .btn-group {
          display: flex;
          align-items: center;

          .confirm-btn {
            width: 165px;
          }
          .cancel-btn {
            margin-left: 36px;
            margin-top: 8px;
            text-decoration: underline;
            font-size: 14px;
          }
        }
        .confirm-success {
          font-size: 16px;
          margin-bottom: 24px;
          font-weight: 700;
          color: #32cd32;
        }
        .confirm-details {
          display: flex;
          flex-direction: column;
          margin-bottom: 30px;

          .confirm-details_small-text {
            font-size: 12px;
            color: #cddcfe;
          }

          .section-content_block {
            display: flex;
            flex-direction: column;
            width: 564px;
            height: 217px;

            .text {
              font-size: 14px;
              margin-bottom: 23px;
            }
            .text1 {
              color: #cddcfe;
              font-size: 14px;
              margin-top: 3px;
            }
            .text2 {
              font-size: 18px;
              font-weight: 700;
              margin-top: 15px;
            }
          }

          .plan-change-summary {
            display: flex;
            justify-content: space-between;

            .summary_label {
              margin-bottom: 5px;
            }

            .summary_value {
              font-size: 16px;
            }
          }
        }
        .plan-details {
          display: flex;
          margin-bottom: 24px;
          .prev-plan {
            width: 270px;
            margin-right: 24px;

            .section-content_block {
              height: 121px;

              .new-plan {
                margin-bottom: 24px;
              }

              .plan-length {
                margin-bottom: 8px;
                line-height: 1;
                font-size: 16px;
                font-weight: 700;
                color: #ffffff;
              }

              .expire {
                color: #cddcfe;
                font-size: 14px;
                // margin-bottom: 18px;
              }
              .edit-plan {
                font-size: 12px;
              }
            }
          }
          .pay-details {
            width: 270px;

            .section-content_block {
              height: 121px;

              .edit-pay {
                font-size: 12px;
              }
              .cc {
                display: flex;
                align-content: center;
                margin-bottom: 24px;

                .cc-img {
                  margin-right: 12px;
                  width: 26px;
                }
                .cc-info {
                  display: flex;
                  flex-direction: column;

                  .cc-name {
                    font-size: 14px;
                  }
                  .cc-exp {
                    font-size: 14px;
                  }
                }
                .cc-owner {
                  font-size: 14px;
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }

    .settings_cancel-plan {
      margin-top: $section-page-margin-top-with-back;

      .cancel-plan {
        .cancel-plan_header {
          margin-bottom: 24px;
          font-family: "Barlow";
          font-size: 36px;
          font-weight: 700;
          text-transform: uppercase;
        }

        .cancel-btn {
          margin-top: 24px;
          width: 170px;
        }

        .cancel-notes {
          padding: 20px;
          width: 100%;
          background-color: #17172e;

          .cancel-notes_header {
            margin-bottom: 24px;
            font-size: 16px;
            font-weight: bold;
          }

          ul {
            padding-left: 0;
            list-style: none;

            li {
              font-size: 14px;
              color: #cddcfe;

              &::before {
                position: relative;
                content: "\2022";
                top: 1px;
                margin-right: 4px;
                color: #ffffff;
              }

              &:not(:last-child) {
                margin-bottom: 8px;
              }
            }
          }
        }
      }
    }

    .settings_renew {
      margin-top: $section-page-margin-top-with-back;

      .resub {
        display: flex;
        flex-direction: column;
        .resub-btn {
          width: 165px;
        }
        .resub-details {
          display: flex;
          flex-direction: column;
          margin-bottom: 30px;

          .section-content_block {
            display: flex;
            flex-direction: column;
            width: 564px;
            height: 113px;

            .total {
              color: #ffffff;
              font-size: 14px;
              font-weight: 700;
              margin-bottom: 12px;
            }
            .price {
              color: #ffffff;
              font-size: 18px;
              font-weight: 700;
              margin-bottom: 6px;
            }
            .date {
              color: #cddcfe;
              font-size: 14px;
            }
          }
        }
        .plan-details {
          display: flex;
          margin-bottom: 24px;

          .prev-plan {
            width: 270px;
            margin-right: 24px;

            .section-content_block {
              height: 85px;

              .plan-length {
                line-height: 1;
                color: #ffffff;
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 12px;
              }
              .expire {
                color: #cddcfe;
                font-size: 14px;
              }
            }
          }
          .pay-details {
            width: 270px;

            .section-content_block {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 85px;

              .edit-pay {
                text-decoration: underline;
                color: #ffffff;
                font-size: 12px;
              }
              .cc {
                display: flex;
                align-items: center;

                .cc-img {
                  margin-right: 12px;
                  width: 26px;
                }
                .cc-info {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }

    .settings_premium {
      .account-status {
        margin-bottom: 36px;

        .current-plan {
          display: flex;

          .plan-status {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 24px;

            .plan-status_sub-text {
              margin-bottom: 2px;
              font-size: 18px;
              color: #cddcfe;
            }
            .plan-status_main-text {
              font-size: 24px;
              font-weight: 700;
              color: #ffffff;
            }

            .plan-status_btns {
              display: flex;
              align-items: center;

              .plan-status_btn {
                width: 215px;
                height: 40px;
                font-size: 14px;

                &:not(:first-child) {
                  margin-left: 24px;
                }
              }
            }
          }
        }

        .features-list {
          display: grid;
          grid-template-columns: repeat(3, 250px);
          grid-column-gap: 65px;
          grid-row-gap: 60px;
          justify-content: center;
          margin-top: 24px;
          margin-bottom: -12px;
          padding: 60px 0px;
          width: 100%;
          border-radius: 4px;
          background: linear-gradient(180deg, #1c1c32 0%, #101029 100%);
          box-shadow: 0 11px 7px 2px rgba(7, 7, 32, 0.09);
        }
      }

      .plans-select {
        .plan-options {
          display: flex;
          align-items: center;

          .boost-plan {
            .sale-tag {
              top: 50%;
              right: -1px;
              transform: translate(50%, -50%);
            }
          }

          .current-plan-text {
            margin-left: 48px;
            font-size: 14px;
            font-weight: bold;
          }
        }

        .cancel-item {
          position: relative;
          display: flex;
          align-items: center;
          margin-top: 24px;
          margin-left: 15px;
          margin-bottom: 24px;
          border-radius: 4px;
          cursor: pointer;

          .form-check-input {
            position: absolute;
            opacity: 0;
          }

          .checkmark {
            margin-right: 12px;
            height: 20px;
            width: 20px;
            background-color: #17172e;
            border-radius: 50%;
            border: 1px solid #5f5f7b;

            &.checked {
              background-color: #ff9b00 !important;
            }
          }
          .item-wrap {
            position: relative;
            .cancel-desc {
              color: #ffffff;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
      .billing-info {
        margin-bottom: 12px;
        .cc {
          display: flex;
          justify-content: space-between;

          .paypal-button {
            align-self: center;
          }

          .cc-details {
            display: flex;
          }

          .cc-img {
            margin-right: 12px;
            width: 64px;
          }
          .cc-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .cc-name {
              font-size: 14px;
              font-weight: 500;
            }
            .cc-exp {
              font-size: 14px;
              color: #cddcfe;
            }
          }
          .cc-owner {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 14px;

            .cc-owner_label {
              font-weight: 700;
            }
            .cc-owner_name {
              color: #cddcfe;
            }
          }
        }
        .premium-btn {
          @extend %flex-center;
          padding: 0px 30px;
          height: 40px;
          font-size: 14px;

          &.hidden {
            visibility: hidden;
            pointer-events: none;
          }
        }
      }

      .account-status {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .account-head {
          display: flex;
          flex-direction: row;
          margin-bottom: 20px;

          .status {
            margin-right: 264px;
            .status-title {
              font-size: 14px;
            }
            .status-basic {
              font-size: 24px;
              font-weight: 700;
            }
          }

          .feature-title {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 10px;
          }

          .feature {
            display: flex;
            flex-direction: row;
            .feature-icon {
              height: 10px;
              width: 11px;
              margin-top: 3px;
              margin-right: 3px;
            }
            .feature-desc {
              font-size: 14px;
            }
          }
        }

        .account-head-premium {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 82px;
          padding: 20px;
          margin-bottom: 20px;
          background-color: #31334a;
          background-image: linear-gradient(270deg, rgba(49, 51, 74, 0.4) 0%, #31334a 57%, #31334a 100%),
            linear-gradient(218deg, #ffffff 0%, #e4c692 1%, #dead5c 42%, #9f6f1e 100%);

          .status {
            width: 355px;
            margin-right: 140px;
            .status-title {
              font-size: 14px;
            }
            .status-basic {
              font-size: 24px;
              font-weight: 700;
            }
            .plan {
              display: flex;
              flex-direction: row;
              .plan-length {
                font-size: 24px;
                font-weight: 700;
                margin-right: 6px;
              }
              .plan-desc {
                font-size: 24px;
                color: "#cddcfe";
              }
            }
          }
          .bill-title {
            font-size: 14px;
            margin-bottom: 5px;
          }
          .bill-date {
            display: flex;
            flex-direction: row;
            .bill-bold {
              font-size: 14px;
              font-weight: 700;
            }
            .bill-reg {
              font-size: 14px;
              margin-right: 4px;
              margin-left: 4px;
            }
          }
        }

        .features {
          display: flex;
          flex-direction: row;
          margin-bottom: 10px;
          .feature-title {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 10px;
          }
          .feature {
            display: flex;
            flex-direction: row;
            .feature-icon {
              height: 10px;
              width: 11px;
              margin-top: 3px;
              margin-right: 3px;
            }
            .feature-desc {
              font-size: 14px;
            }
          }
        }
      }
    }

    .settings_billing {
      .section-header {
        display: flex;

        > div {
          flex: 1;
        }
      }

      .credit-card-img {
        margin-right: 12px;
        width: 36px;
        border: 1px solid var(--border-color-grey);
      }

      .billing-information {
        display: flex;

        .card-name {
          flex: 1;
          display: flex;
          flex-direction: column;
        }

        .billing-address {
          flex: 1;
          display: flex;
          flex-direction: column;

          > span:not(:first-child) {
            margin-top: 2px;
          }
        }
      }

      .edit-billing {
        display: flex;
        // justify-content: space-between;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;

        .default-billing {
          flex: 1;
        }

        .edit-billing_btns {
          display: flex;
          align-items: center;

          .edit-btn {
            margin-right: 12px;
          }

          .edit-btn,
          .delete-btn {
          }
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:39";