html {
  position: relative;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 100%;
  width: 100%;
  float: left;
}

body {
  // font-family: "Helvetica Neue", "Roboto", Arial, Sans-Serif !important;
  margin: 0;
  padding: 0;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.35;
  color: var(--font-color-main);
  text-align: left;
  background-color: var(--ugg-background-main);
  height: auto !important;
}

input,
form {
  font-size: 10px;
}

* {
  box-sizing: border-box;
}

img {
  border: 0;
  vertical-align: middle;

  &.placeholder {
    visibility: hidden;
  }
  &.display-none {
    display: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
  font-weight: normal;
}

.grecaptcha-badge {
  display: none;
}

.flex {
  display: flex;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center,
%flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column,
%flex-column {
  display: flex;
  flex-direction: column;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.justify-content-space-between {
  display: flex;
  justify-content: space-between;
}

.text-center {
  text-align: center;
}

.backslash,
.forward-slash {
  // color: var(--forward-slash);
  color: #5f5f7b;
}

.title {
  // margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: var(--font-color-main);

  > h2 {
    font-size: 12px;
    font-weight: bold;
    color: var(--font-color-main);
    margin: 0;
  }
}

.error-dialogue-box,
%error-dialogue-box {
  align-self: flex-start;
  background-color: #ff4e50;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  padding: 12px;
}

.smcx-widget.smcx-modal {
  display: block !important;
  top: auto !important;
  left: auto !important;
  bottom: 20px !important;
  right: 20px !important;
  margin-left: 0 !important;
  margin-top: 0 !important;

  // &.collapsed {
  //   display: block !important;
  //   left: 80px !important;
  // }
  // &.expanded {
  //   display: block !important;
  //   left: 260px !important;
  // }
}

// Legos Footer
.footer {
  z-index: unset !important;
}

;@import "sass-embedded-legacy-load-done:5";