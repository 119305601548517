.leaderboard_top-five {
  $leaderboard-mobile-bp: 700px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: min-content min-content;
  grid-auto-flow: column;
  grid-gap: 12px;
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 500;

  @media screen and (max-width: $leaderboard-mobile-bp) {
    grid-template-columns: 1fr;
    grid-template-rows: 126px repeat(4, 1fr);
    grid-auto-flow: row;
  }

  .summoner-name {
    display: block;
    font-family: "Barlow";
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
      text-decoration: underline;
    }
  }

  .rank-display {
    display: flex;
    align-items: center;
    color: #cddcfe;
    white-space: nowrap;

    img {
      margin-right: 12px;
      width: 30px;
      flex: 0 0 30px;
    }
  }

  .wr-with-bar {
    @include flex-center;
    flex-direction: column;
    flex: 1;
    color: #cddcfe;

    .wr-text {
      white-space: nowrap;
      &_win-rate {
        font-weight: 700;
      }
    }

    .wr-bar {
      margin-top: 6px;
      width: 100px;
      height: 4px;
      border-radius: 2px;
      background-color: #25254b;
      overflow: hidden;
    }
  }

  .ranking-label {
    @include flex-center;
    padding: 0;
    flex: 0 0 36px;
    width: 36px;
    height: 41px;
    border-radius: 6px;
    font-family: "Barlow";
    font-size: 24px;
    font-weight: 700;

    @include responsive("TABLET") {
      flex: 0 0 32px;
      width: 32px;
      height: 32px;
    }

    &_1 {
      position: relative;
      display: flex;
      align-items: center;
      margin-right: 24px;
      padding: 0 12px 12px;
      flex: 0 0 54px;
      width: 54px;
      height: 71px;
      background-color: #3b2c2e;
      font-size: 36px;
      color: #ff9b00;

      @media screen and (max-width: $leaderboard-mobile-bp) {
        margin-right: 12px;
        padding: 0 6px 6px;
        flex: 0 0 36px;
        width: 36px;
        height: 48px;
        font-size: 24px;
      }

      .label-underline {
        content: "";
        position: absolute;
        left: 12px;
        right: 12px;
        bottom: 12px;
        height: 2px;
        border-radius: 1px;
        background-color: #ff9b00;

        @media screen and (max-width: $leaderboard-mobile-bp) {
          left: 6px;
          right: 6px;
          bottom: 6px;
        }
      }
    }
    &_2 {
      background-color: rgba(50, 115, 250, 0.15);
      color: #3273fa;
    }
    &_3 {
      // background-color: rgba(50, 115, 250, 0.15);
      background-color: #3c2936;
      color: #ff8335;
    }
    &_4,
    &_5 {
      background-color: #232643;
      color: #ffffff;
    }
  }

  .ranking-data-row {
    display: flex;
    align-items: center;
    padding: 0 12px;
    height: 42px;
    border-radius: 6px;
    background-color: #11112a;
  }

  .profile-icon-border-container {
    position: relative;
    flex: 0 0 93px;
    width: 93px;
    height: 93px;
    border-radius: 6px;
    border: 2px solid #414165;
    background-color: #070720;

    @media screen and (max-width: $leaderboard-mobile-bp) {
      flex: 0 0 48px;
      width: 48px;
      height: 48px;
    }

    .level-header {
      position: absolute;
      z-index: 2;
      top: -16px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 20px;
      border-radius: 4px;
      border: 1px solid #414165;
      background-color: #070720;
      color: #ffffff;
      font-size: 11px;
      font-weight: 700;

      @media screen and (max-width: $leaderboard-mobile-bp) {
        width: 26px;
        height: 14px;
        top: -10px;
      }
    }

    .profile-icon-border {
      @include flex-center;
      position: relative;
      border: 2px solid #070720;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .border-notch {
        position: absolute;
        top: -6px;
        left: 50%;
        z-index: 1;
        transform: translateX(-50%);
        width: 40px;
        height: 10px;
        border-radius: 0 0 6px 6px;
        border: 2px solid #070720;
        background-color: #070720;

        @media screen and (max-width: $leaderboard-mobile-bp) {
          width: 30px;
        }
      }

      .profile-icon-image {
        width: 100%;
        height: 100%;
        border-radius: 3px;
      }
    }

    &_small {
      border-radius: 4px;
      border-width: 1px;

      .profile-icon-border {
        border-radius: 4px;
        border-width: 1px;
      }
    }
  }

  .top-five_ranking {
    border-radius: 6px;
    background-color: #191937;
    overflow: hidden;

    .ranking-info-container {
      position: relative;
    }

    .ranking-info-container_mobile {
      display: none;
    }

    .ranking-header {
      display: flex;
      align-items: center;
      width: 100%;

      .ranking-player {
        display: flex;
        align-items: center;
        overflow: hidden;

        .summoner-name {
          flex: 1;
          width: 100%;
        }
      }
    }

    &__other {
      grid-row: 2 / 3;
      padding: 12px;
      overflow: hidden;

      @media screen and (max-width: $leaderboard-mobile-bp) {
        grid-column: 1 / 2;
        grid-row: auto;
      }

      .ranking-info-container {
        .ranking-header {
          @include responsive("MOBILE_LARGE") {
            flex-direction: column;

            .ranking-player {
              justify-content: center;
              width: 100%;

              .summoner-name {
                flex: unset;
              }
            }
          }
        }

        .ranking-user-column {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          overflow: hidden;
        }

        .region-box {
          padding: 3px 6px;
          background-color: #383864;
          border-radius: 2px;

          font-weight: 700;
          font-size: 10px;
          line-height: 11px;
        }

        .summoner-name {
          flex: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-bottom: 3px;
        }

        .profile-icon-border-container {
          margin-right: 12px;
          flex: 0 0 41px;
          width: 41px;
          height: 41px;

          @include responsive("TABLET") {
            margin-right: 8px;
            flex: 0 0 32px;
            width: 32px;
            height: 32px;
          }

          @include responsive("MOBILE_LARGE") {
            flex: 0 0 24px;
            width: 24px;
            height: 24px;
          }
        }

        .ranking-label {
          margin-right: 12px;

          @include responsive("TABLET") {
            margin-right: 8px;
          }
          @include responsive("MOBILE_LARGE") {
            margin-right: 0;
            margin-bottom: 12px;
          }
        }

        .ranking-data-row {
          margin-top: 12px;
        }

        .rank-display {
          img {
            @include responsive("MOBILE_LARGE") {
              width: 24px;
              flex: 0 0 24px;
            }
          }
        }
      }

      @media screen and (max-width: $leaderboard-mobile-bp) {
        .ranking-info-container_mobile {
          display: grid;
          grid-template-columns: 2fr 1fr 1fr;
          grid-auto-flow: column;

          .player {
            display: flex;
            align-items: center;
            margin-right: 12px;
            overflow: hidden;

            .ranking-player {
              display: flex;
              align-items: center;
              overflow: hidden;
            }

            .ranking-label {
              flex: 0 0 24px;
              width: 24px;
              height: 24px;
              font-size: 18px;
            }

            .profile-icon-border-container {
              margin-left: 6px;
              flex: 0 0 24px;
              width: 24px;
              height: 24px;
            }

            .summoner-name {
              margin-left: 6px;
            }
          }

          .rank-display {
            img {
              width: 24px;
              flex: 0 0 24px;

              @include responsive("MOBILE_MEDIUM") {
                display: none;
              }
            }
          }

          .wr-with-bar {
            align-items: flex-end;

            .wr-bar {
              display: none;
            }
          }
        }
      }
    }

    &__1 {
      position: relative;
      display: flex;
      align-items: center;
      grid-area: 1 / 1 / 2 / 5;
      padding: 24px;
      background-image: linear-gradient(223deg, #313160 -100%, #191937 100%);

      .ranking-info-container {
        display: flex;
        align-items: center;
        flex: 1;
      }

      .summoner-name {
        margin-bottom: 5px;
        font-size: 36px;
        width: 100%;

        @media screen and (max-width: $leaderboard-mobile-bp) {
          margin-bottom: 0;
          font-size: 24px;
        }
      }

      .profile-icon-border-container {
        margin-right: 24px;

        @media screen and (max-width: $leaderboard-mobile-bp) {
          margin-right: 12px;
        }
      }

      .ranking-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
        overflow: hidden;

        .ranking-user-row {
          display: flex;
          align-items: center;
        }
      }

      .region-box {
        margin-right: 12px;
        padding: 5px 8px;
        background-color: #383864;
        border-radius: 2px;

        font-weight: 700;
        font-size: 14px;
      }

      .ranking-data-row {
        padding: 0 24px 0 12px;
        .wr-with-bar {
          margin-left: 24px;
        }
      }

      .champion-splash-bg {
        position: absolute;
        width: 400px;
        height: 100%;
        right: 0;
        border-radius: 6px;
        overflow: hidden;

        > img {
          position: absolute;
          right: 0;
          width: 100%;
          mask-image: linear-gradient(to right, rgba(#191937, 0), rgba(#191937, 0.5));
        }
      }

      @media screen and (max-width: $leaderboard-mobile-bp) {
        grid-column: 1 / 2;
        padding: 12px;

        .ranking-info-container_mobile {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .region-box {
            margin-right: 10px;
            padding: 3px 5px;
            font-size: 12px;
          }

          .ranking-data-row {
            margin-top: 12px;
            padding: 0 12px;
          }
        }
      }
    }

    @media screen and (max-width: $leaderboard-mobile-bp) {
      .ranking-info-container {
        display: none;
      }

      .ranking-info-container_mobile {
        position: relative;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:82";