@mixin branch-1 {
  position: relative;
  height: 30px;
  width: 36px;
  margin-left: 24px;
  border-left: 1px solid #565691;
  border-bottom: 1px solid #565691;
}

@mixin branch-2 {
  position: relative;
  height: 30px;
  width: 36px;
  margin-left: 18px;
  border-left: 1px solid #565691;
  border-bottom: 1px solid #565691;
}

@mixin branch-1-x($itemCount) {
  content: "";
  position: absolute;
  left: -1px;
  top: branch-top-offset($itemCount);
  width: 1px;
  height: branch-height($itemCount);
  background-color: #565691;
}

@function branch-height($itemCount) {
  @return 20px + (($itemCount - 1) * 49);
}

@function branch-top-offset($itemCount) {
  @return -(19px + (($itemCount - 1) * 49));
}

.items-home {
  width: 100%;

  .items-home-header {
    margin: 24px 0 36px 0;
    display: flex;
    align-items: center;

    .items-home-header-pic {
      margin-right: 24px;
      flex: none;
    }

    .items-header-text {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      h1 {
        font-family: "Barlow";
        font-weight: 600;
        font-size: 36px;
        line-height: 34px;
        margin: 0;
        padding-bottom: 12px;
      }

      h2 {
        color: #cddcfe;
        font-family: "Inter";
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin: 0;
        strong {
          color: white;
        }
      }
    }
  }

  .items-container {
    display: grid;
    grid-template-columns: minmax(auto, 680px) minmax(auto, 312px);
    grid-template-rows: repeat(4, auto);
    grid-gap: 12px;

    @include responsive("MOBILE_LARGE") {
      grid-template-columns: auto;
      grid-template-rows: repeat(5, auto);
    }

    .items-section {
      padding: 18px;
      border-radius: 3px;
      background-color: #191937;
    }

    .items-recipe {
      grid-area: 1 / 1 / 2 / 2;

      @include responsive("MOBILE_LARGE") {
        grid-area: 2 / 1 / 3 / 2;
      }

      .recipe-container {
        margin-top: 24px;

        .branch-container {
          display: flex;
          flex-direction: column;

          .branch-main {
            display: flex;

            .branch-1-0 {
              @include branch-1;
            }

            @for $i from 1 through 8 {
              .branch-1-#{$i} {
                @include branch-1;
                &:before {
                  @include branch-1-x($i);
                }
              }
            }

            .branch-2-0 {
              @include branch-2;
            }

            @for $i from 1 through 8 {
              .branch-2-#{$i} {
                @include branch-2;
                &:before {
                  @include branch-1-x($i);
                }
              }
            }

            .branch-3-0 {
              @include branch-2;
            }

            @for $i from 1 through 8 {
              .branch-3-#{$i} {
                @include branch-2;
                &:before {
                  @include branch-1-x($i);
                }
              }
            }

            .item-card {
              margin-top: 12px;
            }
          }
        }
      }
    }

    .items-details {
      grid-area: 1 / 2 / 5 / 3;
      padding: 0;
      display: flex;
      flex-direction: column;

      @include responsive("MOBILE_LARGE") {
        grid-area: 1 / 1 / 2 / 2;
      }

      .items-details-container {
        border-radius: 3px;
        background-color: #191937;

        div:last-child {
          border-bottom: none;
        }

        .items-details-header {
          padding: 18px 18px 0 18px;
          border-bottom: 1px solid #070720;
        }

        .items-details-pic {
          margin-bottom: 18px;
        }

        .items-details-section {
          display: flex;
          flex-direction: column;
          padding: 18px;
          border-bottom: 1px solid #070720;

          .stats-list {
            display: flex;
            flex-direction: column;

            font-weight: 500;
            font-size: 14px;
            line-height: 17px;

            :not(:last-child) {
              margin-bottom: 13px;
            }

            .stat-line {
              display: flex;
              align-items: center;

              strong {
                color: #3273fa;
                font-weight: 600;
              }
            }
          }

          .passives-list {
            display: flex;
            flex-direction: column;

            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #cddcfe;

            :not(:last-child) {
              margin-bottom: 18px;
            }

            .passive-line {
              // display: flex;
              strong {
                font-weight: 700;
                color: #ffffff;
              }

              .passive-title {
                font-weight: 700;
                color: #3273fa;
              }

              .magic-damage {
                color: #08a6ff;
              }

              .physical-damage {
                color: #f89249;
              }

              .true-damage {
                color: #ffbf74;
              }

              .healing {
                color: #4dff9f;
              }

              .speed {
                color: #ffffff;
              }

              .health {
                color: #00c280;
              }

              .mana {
                color: #69c9ff;
              }

              .armor {
                color: #f1de30;
              }

              .magic-resistance {
                color: #f928a5;
              }

              .status {
                color: #ffffff;
              }

              .italic {
                font-style: italic;
              }
            }
          }

          .limitation-text {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #cddcfe;

            strong {
              color: #3273fa;
            }
          }

          .stats-recipe-components {
            display: flex;
            justify-content: center;
            margin-bottom: 18px;

            .recipe-img-container {
              display: flex;
              align-items: center;

              .recipe-img {
                margin-right: 10px;
              }
              .plus-icon {
                height: 10px;
                width: 10px;
                margin-right: 10px;
              }
            }
          }

          .stats-recipe-info {
            display: flex;
            justify-content: space-evenly;

            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: white;

            .stats-recipe-data {
              display: flex;
              flex-direction: column;
              // border: 1px solid white;

              .recipe-title {
                margin-bottom: 3px;
                color: #cddcfe;
              }
            }
          }
        }
      }
    }

    .items-builds-into {
      grid-column: 1 / 2;

      // TODO fix columns when resizing
      .builds-into-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 18px 0;

        @include responsive("MOBILE_MEDIUM") {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    .items-cost-analysis {
      padding: 18px 0;
      border-radius: 3px;
      background-color: #191937;
      grid-column: 1 / 2;

      .cost-analysis-container {
        display: flex;
        flex-direction: column;
        margin-top: 24px;

        div:last-child {
          border-bottom: none;
        }

        .cost-section {
          display: flex;
          flex-direction: column;

          border-bottom: 1px solid #070720;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: white;
          padding: 0 18px;

          .item-name-text {
            color: white;
          }

          &:not(:first-child) {
            margin-top: 24px;
          }

          &:not(:last-child) {
            padding-bottom: 24px;
          }

          .cost-analysis-pic {
            margin-right: 6px;
          }
        }

        .stat-values-line {
          display: flex;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 12px;
          }

          strong {
            color: #3273fa;
          }
        }

        .stat-values-line-dot {
          display: flex;
          align-items: center;
          padding-left: 20px;
          position: relative;

          &:not(:last-child) {
            margin-bottom: 12px;
          }

          &::before {
            content: "";
            position: absolute;
            left: 10px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #cddcfe;
          }

          strong {
            color: #3273fa;
          }
        }

        .gold-logo {
          margin-left: 7px;
        }
      }
    }
  }

  .item-card {
    display: flex;

    .item-card-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 12px;

      .text-small {
        color: #cddcfe;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
      }

      .text-default {
        margin-bottom: 4px;

        color: white;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }

  .gold-logo {
    display: flex;
    align-items: center;

    color: #f4c874;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;

    img {
      width: 14px;
      height: 14px;
      padding-right: 4px;
    }
  }
}

.items-pic-xlarge {
  width: 90px;
  height: 90px;
}

.items-pic-large {
  width: 60px;
  height: 60px;
}

.items-pic-medium {
  width: 48px;
  height: 48px;
}

.items-pic-small {
  width: 36px;
  height: 36px;
}

.items-pic-30 {
  width: 30px;
  height: 30px;
}

.items-pic-xsmall {
  width: 18px;
  height: 18px;
}

.items-border-mythic-large {
  border: 2px solid #ff9b00;
  padding: 2px;
  border-radius: 6px;
}

.items-border-normal-large {
  border: 2px solid #565691;
  padding: 2px;
  border-radius: 6px;
}

.items-border-mythic-small {
  border: 1px solid #ff9b00;
  padding: 1px;
  border-radius: 2px;
}

.items-border-normal-small {
  border: 1px solid #565691;
  padding: 1px;
  border-radius: 2px;
}

;@import "sass-embedded-legacy-load-done:65";