.email-verification-page {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
  width: 100%;
  height: 100%;
  justify-content: center;
  min-height: calc(100vh - #{$masthead-height});

  h1 {
    font-size: 30px;
    margin-bottom: 10px;
  }

  p.message {
    margin-top: 0;
    text-align: center;
    margin-bottom: 2em;
    font-size: 16px;
    word-break: break-word;
    max-width: 400px;
  }

  .email-icon {
    height: 72px;
    width: 72px;
  }

  div.btn-blue {
    font-size: 14px;
    width: 200px;
  }
}

;@import "sass-embedded-legacy-load-done:101";