.ad-box-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  border-radius: 3px;
  background-color: #191937;

  .ad-box-label {
    position: absolute;
    left: 4px;
    top: 8px;
    color: #cddcfe;
    font-size: 10px;
  }

  &:has(.ad-box-label-2) {
    padding-bottom: 20px;
  }

  &:has(.ad-box-label-2) {
    padding-bottom: 20px;
  }

  .ad-box-label-2 {
    width: 100%;
    padding: 4px;
    color: #cddcfe;
    font-size: 10px;
  }
}

;@import "sass-embedded-legacy-load-done:28";