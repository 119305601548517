.rune-trees-container {
  display: flex;
  justify-content: space-between;

  .stat-shard-divider {
    margin: 12px 0;
    width: 100%;
    height: 1px;
    background-color: #414165;

    &.stat-shard-divider_mobile {
      margin: 6px 0;
    }
  }
}

.rune-tree {
  display: flex;
  flex-direction: column;
  align-items: center;

  .rune-tree_header {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    padding: 6px;
    width: 244px;
    height: 48px;
    border-radius: 6px;
    background-color: #11112a;

    .rune-tree_mobile & {
      margin-bottom: 12px;
      width: 139px;
    }

    .perk-style-title {
      margin-left: 6px;
      font-size: 14px;
      font-weight: 700;
      display: flex;
      vertical-align: center;

      .rune-tree_mobile & {
        font-size: 12px;
      }

      svg {
        margin-left: 6px;
        width: 6px;

        path {
          fill: white;
        }
      }
    }

    .rune-image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 2px;
      background-color: #191937;

      img {
        padding: 7%;
        width: 100%;
      }
    }
  }

  &.primary-tree {
    .perk-row {
      margin-bottom: 18px;

      .rune-tree_mobile & {
        margin-bottom: 16px;
      }
    }
  }

  .perk-row {
    display: flex;
    align-items: center;
    display: grid;
    grid-template-columns: 8px 1fr;
    grid-auto-flow: column;
    margin-bottom: 12px;
    width: 100%;

    .rune-tree_mobile & {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }

    &.keystone-row {
      padding-bottom: 18px;
      border-bottom: 1px solid #414165;

      .rune-tree_mobile & {
        margin-bottom: 12px;
        padding-bottom: 12px;
      }

      .perks {
        margin: 0 0 0 28px;

        .rune-tree_mobile & {
          margin: 0 5px;
        }
      }
    }

    .row-marker {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .rune-tree_mobile & {
        display: none;
      }

      &::before {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #25254b;
      }

      &.row-active {
        &::before {
          background-color: #ffffff;
        }
      }
    }

    &:not(.keystone-row):not(.stat-shard-row) {
      .perks {
        margin: 0 8px 0 36px;

        .secondary-tree & {
          margin: 0 11px 0 39px;
        }

        .rune-tree_mobile & {
          margin: 0 5px;
        }
      }
    }
    &.stat-shard-row {
      .perks {
        margin: 0 14px 0 42px;

        .rune-tree_mobile & {
          margin: 0 8px;
        }
      }
    }

    .perks {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .rune-tree_mobile & {
        width: 100%;
      }

      .perk {
        justify-self: center;
        flex: 0 0 36px;
        width: 36px;
        height: 36px;
        border-radius: 50%;

        .rune-tree_mobile & {
          flex: 0 0 24px;
          width: 24px;
          height: 24px;

          .secondary-tree & {
            flex: 0 0 24px;
            width: 24px;
            height: 24px;
          }
        }

        .secondary-tree & {
          flex: 0 0 30px;
          width: 30px;
          height: 30px;
        }

        > img {
          width: 100%;
        }

        &.keystone {
          flex: 0 0 52px;
          width: 52px;
          height: 52px;

          .rune-tree_mobile & {
            flex: 0 0 34px;
            width: 34px;
            height: 34px;
          }
        }

        &.perk-active:not(.keystone) {
          border: 2px solid #3273fa;

          .rune-tree_mobile & {
            border-width: 1px;
          }
        }
        &.perk-inactive {
          filter: grayscale(100%);
          opacity: 0.27;
        }
      }

      .shard {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 24px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid transparent;

        > img {
          width: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-indent: 100%;
        }

        .rune-tree_mobile & {
          flex: 0 0 18px;
          width: 18px;
          height: 18px;

          > img {
            width: 12px;
          }
        }

        &.shard-active {
          border: 2px solid #3273fa;
          background-color: #11112a;

          .rune-tree_mobile & {
            border-width: 1px;
          }
        }
        &.shard-inactive {
          background-color: #25254b;
          > img {
            filter: grayscale(100%);
            opacity: 0.3;
          }
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:21";