.item-timeline {
  margin-bottom: -5px;
}

.items-purchased-wrapper {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12px;
  margin-bottom: 5px;

  &:last-child {
    margin-right: 0;
  }

  .items-purchased-by-minute {
    display: flex;
    align-items: center;
    padding: 6px;
    border-radius: 3px;
    background-color: #25254b;

    // .match_gold & {
    //   background-color: #563a17;
    // }
    // .match_win & {
    //   background-color: #223b80;
    // }
    // .match_lose & {
    //   background-color: #53263e;
    // }

    // .match_remake & {
    //   background-color: #252554;
    // }

    > div {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 6px;
      }
    }

    .item-purchased {
      position: relative;

      .item-count {
        position: absolute;
        z-index: 2;
        right: -5px;
        bottom: -5px;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.8);
        color: #ffffff;
        font-weight: bold;
        cursor: default;
        pointer-events: none;
      }

      .item-sold {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 15%;
        font-weight: bold;
        cursor: default;
        pointer-events: none;

        .item-sold-img {
          position: relative;
          bottom: 3px;
          left: 3px;
          height: 10px;
          width: 10px;
          fill: $ugg-red;
        }
      }
    }
  }

  .timestamp {
    margin-top: 6px;
    color: #cddcfe;
  }

  .separator {
    position: absolute;
    right: -12px;
    top: 14px;
    width: 12px;
    height: 12px;
    background-color: #25254b;

    // .match_gold & {
    //   background-color: #563a17;
    // }
    // .match_win & {
    //   background-color: #223b80;
    // }
    // .match_lose & {
    //   background-color: #53263e;
    // }

    // .match_remake & {
    //   background-color: #252554;
    // }
  }
}

;@import "sass-embedded-legacy-load-done:23";