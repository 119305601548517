@font-face {
  font-family: "Proxima Nova Lt";
  src: url("../fonts/ProximaNova/ProximaNova-Light.otf") format("opentype");
}

@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova/ProximaNova-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova/ProximaNova-Bold.otf") format("opentype");
  font-weight: 500;
}

/*@font-face {
	font-family: 'Proxima Nova';
	src: url('/fonts/Proxima Nova/ProximaNova-Light.otf');
	font-weight: lighter;
}
*/

@font-face {
  font-family: "DS Digital";
  src: url("../fonts/Digital/DS-DIGI.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "DS Digital";
  src: url("../fonts/Digital/DS-DIGIB.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "DS Digital";
  src: url("../fonts/Digital/DS-DIGII.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "DS Digital";
  src: url("../fonts/Digital/DS-DIGIT.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

;@import "sass-embedded-legacy-load-done:102";