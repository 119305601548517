.match-summary_desktop {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  //padding: 0 12px;

  &:hover:not(.dropdown-disabled) {
    cursor: pointer;
  }

  .new-match-notification-container {
    position: relative;
    display: flex;
    justify-content: center;
    // align-items: center;
    margin: 2px 0;
    height: 27px;
    min-width: 70px;
    transition: height 300ms, transform 300ms;
    transform: translateZ(0) perspective(1px);
    -webkit-font-smoothing: antialiased;
    backface-visibility: hidden;

    &_exited {
      transform: translateZ(0) perspective(1px);
      height: 5px;
    }

    .new-match-tag {
      @include flex-center;
      position: absolute;
      top: 50%;
      padding: 3px 6px;
      border-radius: 3px;
      background-color: rgba(255, 155, 0, 0.27);
      color: #ff9b00;
      font-size: 10px;
      font-weight: 700;
      opacity: 1;
      transform: scale(1) translateY(-50%) translateZ(0) perspective(1px);
      transform-origin: top;
      transition: opacity 300ms, transform 300ms;
      backface-visibility: hidden;

      &_exited {
        opacity: 0;
        transform: scale(0.3) translateZ(0) perspective(1px);
      }
    }
    .lp-display_transition {
      @include flex-center;
      position: absolute;
      top: 50%;
      height: 100%;
      opacity: 0;
      transform: scale(0.6) translateY(-50%) translateZ(0) perspective(1px);
      transform-origin: top;
      transition: opacity 300ms, transform 300ms;
      -webkit-font-smoothing: antialiased;
      backface-visibility: hidden;

      &_entered {
        opacity: 1;
        transform: scale(1) translateY(-50%) translateZ(0) perspective(1px);
      }
    }
  }

  .icons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 30px;
    padding: 7px 0 7px 0;
    border-radius: 0 3px 3px 0;
    cursor: pointer;

    .match_gold & {
      background-color: rgba(255, 155, 0, 0.18);
      // &.is-expanded {
      //   background-color: rgba(255, 155, 0, 0);
      // }
    }
    .match_win & {
      background-color: #22397c;
    }
    .match_win.is-expanded &,
    .match_win.is-hovered & {
      background-color: #1e2b5e;
    }

    .match_lose & {
      background-color: #53263e;
    }
    .match_lose.is-expanded &,
    .match_lose.is-hovered & {
      background-color: #3b213b;
    }

    .match_remake & {
      background-color: #252554;
    }
    .match_remake.is-expanded &,
    .match_remake.is-hovered & {
      background-color: #191937;
    }
  }

  .content-container {
    // display: grid;
    // grid-template-columns: 16% 17% 15% minmax(124px, 20%) minmax(164px, 30%) 18px;
    // grid-template-columns: 16% 17% 15% minmax(120px, 22%) minmax(155px, 31%) 5%;
    // grid-template-columns: 16% 19% 15% minmax(120px, 22%) minmax(155px, 31%);
    // grid-template-rows: 78px;
    display: flex;
    align-items: center;
    width: 100%;

    @include responsive("TABLET") {
      justify-content: space-around;
    }

    // @media screen and (max-width: 720px) {
    //   grid-template-columns: 18% 30% 18% minmax(124px, 31%) 18px;
    // }

    .group-one {
      width: 99px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .row-one {
        display: flex;
        flex-direction: column;
        align-items: center;
        // margin-bottom: 7px;

        .queue-type {
          font-size: 11px;
          font-weight: 700;
          white-space: nowrap;
          text-align: center;
        }

        .from-now {
          font-size: 10px;
          font-weight: 500;
          color: #cddcfe;
        }
      }

      .row-two {
        display: flex;
        flex-direction: column;
        align-items: center;

        // &.row-two_spacing {
        //   height: 7px;
        // }

        .match-lp {
          display: flex;
          align-items: center;
          // margin-top: -5px;

          &.lp-missing {
            .lp-value {
              margin: 0;
            }
            svg {
              flex: 0 0 14px;
              width: 14px;
            }
          }

          .lp-arrow {
            width: 9px;
            flex: 0 0 9px;
          }

          .lp-diff-container {
            @include flex-center;
            height: 28px;

            &.ranked-up {
              .lp-gain_loss,
              .promos {
                display: none;
              }
              .rank-up {
                display: flex;
                .lp-arrow {
                  margin-right: 2px;
                }
              }
              &:hover {
                .lp-gain_loss,
                .promos {
                  display: flex;
                }
                .rank-up {
                  display: none;
                }
              }
            }

            &.promotion-failed {
              .lp-gain_loss {
                display: none;
              }

              &:hover {
                .lp-gain_loss {
                  display: flex;
                }
                .promos {
                  display: none;
                }
              }
            }
          }

          .rank-up {
            .promoted-tier-img {
              width: 27px;
              flex: 0 0 27px;
            }
            .promoted-rank {
              color: #ffffff;
              font-size: 14px;
              font-weight: 700;
            }
          }

          .placement-progress {
            .current-placement-match {
              font-size: 18px;
              line-height: 1;
            }
            .total-placement-matches {
              font-size: 10px;
            }
          }

          .lp-value {
            margin-left: 5px;
            font-size: 18px;
            font-weight: 700;
            white-space: nowrap;
          }

          .no-diff {
            color: #5f5f7b;
            font-size: 18px;
            font-weight: 700;
          }

          .promos {
            // height: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .promo-target {
              margin-bottom: 3px;
              > img {
                width: 18px;
              }
            }
          }
        }
      }

      .row-three {
        display: flex;
        // flex-direction: column;
        align-items: center;

        .game-duration {
          font-size: 12px;
          font-weight: 500;
          color: #cddcfe;
        }

        .victory-status {
          font-size: 12px;
          font-weight: 700;

          .match_gold & {
            color: #ff9b00;
          }
          .match_win & {
            color: #3273fa;
          }
          .match_lose & {
            color: #ff4e50;
          }
          .match_remake & {
            color: #cddcfe;
          }
        }
      }
    }

    .group-two {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 94px;
      margin-left: 6px;

      .row-one {
        display: flex;
        align-items: center;
        justify-content: center;

        .champion {
          position: relative;
          margin-right: 2px;

          .champion-face {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            border-radius: 3px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              transform: scale(1.1);
            }
          }

          .champion-level {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 16px;
            border-radius: 3px;
            background-color: #191937;
            font-size: 10px;
            font-weight: 500;
            text-align: center;
          }
        }

        .summoner-spells {
          display: flex;
          flex-direction: column;
          margin-right: 2px;

          .summoner-spell {
            width: 22px;
            height: 22px;
            border-radius: 3px;
            background-color: #191937;

            &:first-child {
              margin-bottom: 2px;
            }

            > img {
              width: 100%;
              height: 100%;
              border-radius: 3px;
            }
          }
        }

        .runes {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;

          .rune {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 22px;
            background-color: #191937;
            border-radius: 3px;

            .match_gold & {
              background-color: #563a17;
            }
            .match_win & {
              background-color: #223b80;
            }
            .match_lose & {
              background-color: #53263e;
            }

            .match_remake & {
              background-color: #252554;
            }

            &:first-child {
              margin-bottom: 2px;
            }

            img {
              width: 22px;
              height: 22px;
            }

            &_sub-style {
              img {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
      .multi-kill:not(:empty) {
        display: flex;
        align-items: center;
        margin-top: 10px;
      }
      .carry-score-container {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .medal-icon {
          margin-left: 6px;
          flex: 0 0 8px;
          width: 8px;
        }

        .carry-score {
          display: flex;
          align-items: center;
          height: 18px;
          border-radius: 9px;
          background-color: #145a66;

          .score-grade {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            color: #ffffff;
            font-size: 12px;
            font-weight: 700;
          }
          .score-text {
            padding: 0 8px 0 4px;
            color: #cddcfe;
            font-size: 10px;
            font-weight: 500;

            span {
              color: #ffffff;
            }
          }

          &_S {
            border: 1px solid #ff9b00;
            background-color: #533f38;

            .score-grade {
              background-color: #ff9b00;
            }
          }
          &_A {
            border: 1px solid #05bdcf;
            background-color: #154b72;

            .score-grade {
              background-color: #05bdcf;
            }
          }
          &_B,
          &_C {
            border: 1px solid transparent;
            background-color: #154b72;

            .score-grade {
              background-color: #05bdcf;
            }
          }
          &_D {
            border: 1px solid transparent;
            background-color: #191937;

            .score-grade {
              background-color: #383864;
            }
          }
        }
      }
    }
  }

  .post-stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 80px;
    margin-left: 14px;

    .KDA-totals {
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      white-space: nowrap;
      .red {
        color: #ff4e50;
      }

      .slash {
        color: #5f5f7b;
      }
    }
    .vision-value {
      // flex: 1;
      padding: 4px 0 0 0;
      font-size: 11px;
      font-weight: 400;
      color: #cddcfe;
    }

    .KDA-ratio {
      margin-top: 6px;
      line-height: 13px;
      font-size: 11px;
      font-weight: 700;

      .faded {
        color: #cddcfe;
        font-weight: 400;
      }
    }
    .cs {
      margin-top: 6px;
      line-height: 13px;
      font-size: 11px;
      color: #cddcfe;

      .faded {
        color: #cddcfe;
        font-weight: 400;
      }
    }

    //.multi-kill:not(:empty) {
    //  margin-top: 10px;
    //}
  }

  .group-three {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 14px;
    padding: 0 12px;
    width: 94px;

    .vision-row {
      display: flex;
      align-items: center;
      margin-top: 10px;
      // width: 100px;
      border-radius: 9px;
      background-color: #191937;

      .vision-icon-container {
        @include flex-center;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #383864;

        svg {
          width: 12px;
          flex: 0 0 12px;
        }
      }

      //.vision-value {
      // flex: 1;
      //  padding: 0 8px 0 4px;
      //  font-size: 10px;
      //  font-weight: 500;
      //  color: #cddcfe;
      //}
    }

    .items {
      display: flex;
      .item-wrapper {
        border-radius: 3px;
        background-color: #383864;
        //background-color: #22397C;
        overflow: hidden;
        .match_gold & {
          background-color: rgba(86, 58, 23, 1);
        }
        .match_win & {
          background-color: rgba(34, 59, 128, 1);
        }
        .match_lose & {
          background-color: rgba(83, 38, 62, 1);
        }

        .match_remake & {
          background-color: rgba(37, 37, 84, 1);
        }
      }

      .main-items {
        .items-container {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-gap: 2px;
          justify-content: space-between;
        }
      }

      .trinket-icon {
        display: flex;
        //align-items: center;
        justify-content: center;
        margin-left: 2px;
      }
    }
  }

  .group-four {
    display: flex;
    margin-left: 18px;
    max-width: 194px;
    flex: 1;

    @media screen and (max-width: 720px) {
      display: none;
    }

    .team-list:first-of-type {
      margin-right: 4px;
    }

    .team-list {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .summoner-entry {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 2px;
        }

        .champion-face {
          @include flex-center;
          margin-right: 5px;
          height: 15px;
          width: 15px;
          border-radius: 2px;
          overflow: hidden;
        }

        .summoner-name {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 60px;

          a {
            font-size: 11px;
            font-weight: 500;
            color: #cddcfe;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
              color: lighten(#bbbedb, 20%);
            }
          }
        }

        &.chosen_win {
          .champion-face {
            border: 1px solid #ff9b00;
            // border: 1px solid #3273fa;
            border-radius: 8px;
          }

          .summoner-name > a {
            color: white;
            font-weight: 700;
          }
        }

        &.chosen_loss {
          .champion-face {
            border: 1px solid #ff9b00;
            // border: 1px solid #ff4e50;
            border-radius: 8px;
          }

          .summoner-name > a {
            color: white;
            font-weight: 700;
          }
        }
      }
    }
  }

  .group-five {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

;@import "sass-embedded-legacy-load-done:56";