.boost-plan {
  position: relative;
  display: flex;
  align-items: center;
  width: 324px;
  min-height: 135px;
  border-radius: 4px;

  .plan-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    align-self: stretch;
    flex: 0 0 51px;
    width: 51px;
    border-radius: 4px 0 0 4px;
    background-color: #3273fa;

    .plan-radio-button-container {
      width: 24px;
      height: 24px;
      border: 1px solid #054bdd;
      border-radius: 50%;

      input.radio-button {
        appearance: none;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        width: 100%;
        height: 100%;
        border: 1px solid #1959dd;
        border-radius: 50%;
        background-image: linear-gradient(to top, #3273fa 0%, #0e47be 100%);

        &:checked::before {
          content: "";
          position: absolute;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-image: linear-gradient(180deg, #ffcb00 0%, #ff9b00 100%);
        }
      }
    }
  }

  .term {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    flex: 1;
    padding: 10px 24px;

    .details {
      color: #1c1c32;
      font-size: 12px;
      margin-top: 10px;

      a {
        color: black;
        text-display: none;
      }
    }

    &.discounted {
      .price {
        font-size: 28px;
      }
    }

    .discounted-subtotal {
      color: #8394bb;
      text-decoration: line-through;
      text-decoration-color: black;
      padding-right: 8px;
    }

    .plan-type {
      font-family: "Proxima Nova";
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #3273fa;

      &.free-trial {
        text-align: center;
        font-size: 45px;
      }
    }

    .plan_cta {
      font-family: "Barlow";
      font-size: 20px;
      font-weight: 400;
      color: #5f5f7b;

      .bolded {
        color: #1c1c32;
        font-weight: 600;
      }
    }

    .price {
      font-family: "Barlow";
      font-size: 36px;
      color: #1c1c32;

      .backslash {
        color: #5f5f7b;
      }
    }
  }

  .sale-tag {
    position: absolute;
    top: -18px;
    right: -18px;
    width: 70px;
  }

  &._has-hover {
    cursor: pointer;
  }

  &._text-center {
    text-align: center;
  }

  &.plan_none {
    display: flex;
    align-items: center;
    background-color: #1c1c32;

    .term {
      font-family: "Barlow";
      font-size: 24px;
      font-weight: 700;
      color: #ffffff;
      text-transform: uppercase;
    }

    img {
      position: absolute;
      top: 0;
      right: 12px;
    }
  }

  &.plan_trial {
    background-color: #1c1c32;

    .plan-type {
      color: #ff9b00;
    }

    .price {
      color: #ffffff;
    }
  }

  &.plan_year {
    background-color: #ffffff;
  }

  &.plan_month {
    background-color: #cddcfe;
  }
}

.boost-feature {
  text-align: center;

  .feature_icon-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 67px;

    .feature_icon {
    }

    .coming-soon-tag {
      position: absolute;
      right: -56px;
      top: -14px;
    }
  }

  .feature_header {
    margin-top: 17px;
    margin-bottom: 14px;
    font-family: "Barlow";
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
  }

  .feature_description {
    font-size: 14px;
    color: #cddcfe;
    text-align: center;
  }

  &.boost-feature_large {
    .feature_icon-container {
      height: 63px;

      .coming-soon-tag {
        right: -56px;
        top: -14px;
      }
    }

    .feature_header {
      margin-top: 24px;
      margin-bottom: 12px;
      font-size: 24px;
    }

    .feature_description {
      font-size: 18px;
    }
  }
}

.payment-options-container {
  background-color: #11112a;
  display: flex;
  flex-direction: column;
  border-radius: 4px;

  & > button {
    &:hover {
      cursor: pointer;
      background-color: #25254b;
    }
  }

  .payment-options {
    padding: 18px;
    display: flex;
    align-items: center;
    background-color: inherit;
    border: none;

    &.credit-card-container {
      flex-direction: column;
      align-items: inherit;

      & > div:not(:last-child) {
        padding-bottom: 18px;
      }

      .payment-input-label {
        margin-bottom: 8px;
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
      }
    }

    .payment-label {
      padding-left: 12px;
      color: #ffffff;
      font-family: "Barlow";
      font-size: 14px;
      font-weight: 700;
    }

    &:not(:last-child) {
      border-bottom: 1px #2c2c40 solid;
    }

    &:first-child {
      border-radius: 4px 4px 0px 0px;
    }

    &:last-child {
      border-radius: 0px 0px 4px 4px;
    }
  }
}

.payment-form-element {
  .payment-form-input {
    display: flex;
    align-items: center;
    padding: 0 8px;
    height: 40px;
    border-radius: 3px;
    background-color: #31334a;

    iframe {
      color-scheme: none;
    }

    &.is-focused {
      outline-offset: -2px;
      outline: -webkit-focus-ring-color auto 5px;
    }
  }
}

.premium-checkout {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 64px;
  padding-bottom: 64px;
  width: 100%;
  // background-image: url("https://static.bigbrain.gg/assets/ugg/pages/general/dark-wave.svg");
  // background-position: bottom;
  // background-repeat: no-repeat;
  // background-size: 100%;

  .premium-checkout_form {
  }

  .boost-features-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 56px;
    grid-row-gap: 47px;
    margin-left: 110px;
    padding: 72px 59px;
    width: 582px;
    box-shadow: 0 11px 7px 2px rgba(7, 7, 32, 0.09);
    border-radius: 4px;
    background-image: linear-gradient(180deg, #1c1c32 0%, #101029 100%);
  }

  .ad-free {
    margin-left: 48px;
    padding-bottom: 24px;
    width: 582px;
    border-radius: 4px;
    background-image: linear-gradient(225deg, #313160 -100%, #191937 100%);

    .chibis {
      display: flex;
      justify-content: center;
      margin-top: 44px;
      width: 100%;

      > img {
        width: 120%;
      }
    }

    .ad-free_main-text {
      margin: 48px 48px 0;
      font-family: "Barlow";
      font-size: 30px;
      font-weight: 500;
      color: #ffffff;
    }
    .ad-free_sub-text {
      margin: 22px 48px 0;
      font-size: 16px;
      color: #cddcfe;
    }

    .ad-free_header {
      display: flex;
      align-items: center;
      margin: 0 48px;
      padding: 42px 0 48px;
      border-bottom: 1px solid #383864;

      .ad-free_header_text {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 24px;
        line-height: 30px;

        strong {
          font-family: "Barlow";
          font-size: 25px;
          font-weight: 700;
          color: #3273fa;
        }
        span {
          font-size: 25px;
          font-weight: 500;
          color: #cddcfe;

          &.backslash {
            color: #5f5f7b;
          }
        }
      }

      .heart-icon {
        width: 57px;
        flex: 0 0 57px;

        path {
          fill: #3273fa;
        }
      }
    }
  }
}

.premium-plan-selection {
  box-shadow: 0 11px 7px 2px rgba(7, 7, 32, 0.09);
  font-family: "Barlow";

  .plan-option {
    display: flex;
    align-items: center;
    padding-left: 24px;
    width: 324px;
    height: 90px;

    &:first-child {
      border-radius: 4px 4px 0 0;
    }
    &:last-child {
      border-radius: 0 0 4px 4px;
    }
    &:hover {
      cursor: pointer;
    }

    .radio-button-container {
      @include flex-center;
      flex: 0 0 24px;
      padding: 1px;
      width: 24px;
      height: 24px;
      border-radius: 50%;

      input[type="radio"] {
        position: relative;
        margin: 0;
        flex: 0 0 100%;
        width: 100%;
        height: 100%;
        @include flex-center;
        border-radius: 50%;
        appearance: none;

        &:checked::before {
          content: "";
          position: absolute;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-image: linear-gradient(180deg, #ffffff 0%, #cddcfe 100%);
        }
      }
    }

    .plan-desc {
      display: flex;
      flex-direction: column;
      margin-left: 24px;

      .plan-period {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        color: #cddcfe;

        .plan-discount {
          margin-left: 8px;
          padding: 2px 8px;
          box-shadow: 0 11px 7px 2px rgba(7, 7, 32, 0.09);
          border-radius: 2px;
          border: 1px solid #ff9b00;
          background-color: rgba(255, 155, 0, 0.2);
          font-size: 14px;
          font-weight: 600;
          color: #ff9b00;
        }
      }

      .plan-price {
        font-size: 25px;
        strong {
          font-weight: 700;
          color: #ffffff;
        }
      }
    }

    &.plan-option_unselected {
      background-color: #191937;

      &:hover {
        // background-color: lighten(#191937, 2%);
        background-color: #25254b;
      }
      .radio-button-container {
        background-image: linear-gradient(180deg, #2a2a51 0%, #11112a 100%);

        input[type="radio"] {
          background-image: linear-gradient(180deg, #0c0c21 0%, #212145 100%);
        }
      }
    }
    &.plan-option_selected {
      background-color: #3273fa;

      .radio-button-container {
        background-image: linear-gradient(180deg, #3273fa 0%, #588dfc 100%);

        input[type="radio"] {
          background-image: linear-gradient(180deg, #1c5ce1 0%, #3273fa 100%);
        }
      }
    }
  }
}

.premium-payment {
  width: 324px;

  .selection-title {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 500;
    color: #cddcfe;
  }

  .payment-form {
    margin-top: 36px;

    .premium-billing_errors {
      .premium-billing_error {
        color: $ugg-red;
        font-size: 12px;

        &:not(:first-child) {
          margin-top: 6px;
        }
      }
    }

    .credit-card,
    .zip-code,
    .exp-date,
    .cvc {
      flex: 0 0 calc(50% - 9px);
    }

    .submit-billing-btn {
      margin-top: 12px;
      height: 40px;
    }

    .switch-payment-btn {
      display: block;
      margin-bottom: 24px;
      font-size: 16px;
      font-weight: 500;
      text-decoration: underline;
    }
  }
}

.billing-disclaimer {
  margin-top: 24px;
  color: #cddcfe;
  font-size: 12px;
  font-weight: 500;
}

.premium-confirmation {
  &.confirmation_welcome-to-boost {
    position: fixed;
    top: 30%;
    left: 50%;
    @extend %flex-center;
    flex-direction: column;
    padding: 0 45px;
    width: 550px;
    height: 486px;
    border-radius: 2px;
    transform: translateX(-50%);
    background-color: #1c1c32;

    .close-corner {
      position: absolute;
      top: 0;
      right: 0;
    }

    .close-icon {
      position: absolute;
      top: 28px;
      right: 28px;
      width: 16px;
      height: 16px;

      path {
        fill: #ffffff;
      }
    }

    .header-1 {
      margin-top: 36px;
      font-family: "Barlow";
      font-size: 48px;
      font-weight: 700;
      line-height: 1;
    }

    .header-2 {
      margin-bottom: 36px;
      font-family: "Barlow";
      font-size: 36px;
      font-weight: 500;
      color: #cddcfe;
    }

    div {
      font-size: 16px;
      text-align: center;

      &.message {
        line-height: 24px;

        &.text-1 {
          font-weight: 700;
        }

        &.text-2 {
          color: #cddcfe;
        }
      }
    }
  }

  &.confirmation_ad-free {
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    @include flex-center;
    flex-direction: column;
    padding: 60px 0 50px;
    width: 550px;
    height: 431px;
    border-radius: 6px;
    background-image: linear-gradient(225deg, #313160 -100%, #191937 100%);

    .close-icon {
      position: absolute;
      top: 28px;
      right: 28px;
      width: 16px;
      height: 16px;

      path {
        fill: #ffffff;
      }
    }

    .header-1 {
      margin-bottom: 18px;
      font-family: "Barlow";
      font-size: 48px;
      font-weight: 700;
      color: #ffffff;
    }
    .header-2 {
      margin-bottom: 48px;
      font-family: "Barlow";
      font-size: 36px;
      font-weight: 500;
      color: #cddcfe;
    }
    .text-1 {
      padding: 0 60px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #cddcfe;
      text-align: center;
    }
  }
}

.premium-banner {
  background-image: url("https://static.bigbrain.gg/assets/lol/art/premium/premium-banner-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 1015px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  cursor: pointer;

  .text-labels {
    display: flex;
    flex-direction: column;

    .unlock-profile {
      background: linear-gradient(
        180deg,
        #ebd5b0 0%,
        #e4c692 28%,
        #dead5c 68%,
        #daa346 100%
      ); // background color masked along the text and clip away the rest
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: "Barlow";
      font-size: 24px;
      font-weight: 600;
    }

    .full-match {
      color: #cddcfe;
      font-family: "Barlow";
      font-size: 16px;
      font-weight: 300;
    }
  }

  .try-premium {
    cursor: pointer;
    color: #070720;
    font-size: 12px;
    font-weight: 700;
    width: 148px;
    height: 35px;
    background: linear-gradient(180deg, #ebd5b0 0%, #e4c692 28%, #dead5c 68%, #daa346 100%);
    border: #daa346;
  }
}

;@import "sass-embedded-legacy-load-done:96";