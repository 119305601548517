.password-reset-page {
  margin: 60px auto 0;
  width: 332px;

  .divider {
    height: 1px;
    background-color: #bbbedb;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  h1 {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
  }

  p {
    margin-top: 12px;
    margin-bottom: 0;
    font-size: 12px;

    &.error-msg {
      font-size: 14px;
      font-weight: bold;
      color: $ugg-red;
    }
  }

  label {
    display: block;
    margin-top: 25px;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: bold;
  }

  .password-checker {
    margin-top: 12px;
  }

  .set-password-loader {
    margin-top: 24px;
    width: 36px;
  }

  .set-password-btn {
    margin-top: 24px;
    width: 117px;
  }
}

;@import "sass-embedded-legacy-load-done:38";