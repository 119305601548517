.switch-container {
  position: relative;
  // width: 80px;
  // height: 40px;
  border-radius: 25px;
  // background-color: lightgrey;

  .switch-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    transform: translateX(0%);
    transition: all 0.2s ease;
  }

  input:checked + .switch-btn {
    left: 100%;
    transform: translateX(-100%);
  }
}

;@import "sass-embedded-legacy-load-done:30";