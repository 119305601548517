.played-champions {
  display: flex;
  flex-direction: column;

  @include responsive("DESKTOP_SMALL") {
    grid-row: 2 / 3;
  }

  @include responsive("MOBILE_LARGE", false) {
    grid-column: 1 / 3;
  }

  @include responsive("MOBILE_MEDIUM") {
    grid-row: auto;
  }

  .played-champions_loading,
  .played-champions_empty {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #191937;
    flex: 1;
    color: #cddcfe;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    min-height: 100px;
  }

  .played-champions_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 18px;
    border-radius: 3px 3px 0px 0px;
    border-bottom: 1px solid #070720;
    background-color: #191937;
    font-weight: 700;
    color: #ffffff;
    font-size: 14px;
    height: 57px;

    @include responsive("MOBILE_LARGE") {
      height: 43px;
      padding: 0 12px;
    }

    .title {
      display: flex;
      font-size: 14px;
      font-weight: 700;
    }

    .filter-select-transparent {
      background-color: transparent;

      & > div {
        background-color: transparent;
        padding: 0;
      }

      & > div > div > div {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .champion-list {
    .champion-performance {
      display: flex;
      align-items: center;
      padding: 12px 18px;
      background-color: #191937;

      &:not(:first-child) {
        border-top: 1px solid #070720;
      }

      @include responsive("TABLET", false) {
        &:nth-child(n + 6) {
          display: none;
        }
      }
      @include responsive("MOBILE_LARGE", false) {
        &:nth-child(n + 6) {
          display: none;
        }
      }
      @include responsive("MOBILE_LARGE") {
        padding: 12px;
      }

      // &:hover {
      //   background-color: #25254B;
      // }

      .champion-face {
        position: relative;
        @include flex-center;
        margin-right: 12px;
        width: 34px;
        height: 34px;
        border-radius: 2px;
        overflow: hidden;

        > div {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scale(1.1);
        }
      }

      .champion-stats {
        display: grid;
        grid-template-columns: 0.8fr 1fr 1fr;
        flex: 1;
        font-size: 11px;
        font-weight: 500;
        line-height: 13px;

        @include responsive("TABLET") {
          grid-template-columns: 1fr 0.5fr 0.6fr;
        }

        &_col {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 32px;
        }

        &_col-1 {
          overflow: hidden;
        }
        &_col-2 {
          align-items: center;
        }
        &_col-3 {
          align-items: flex-end;
        }

        .champion-name {
          flex: 1;
          font-size: 12px;
          font-weight: 700;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .champion-name-center {
          flex: unset;
        }

        .win-rate {
          font-size: 12px;
          font-weight: 600;
          white-space: nowrap;
        }

        .kda-ratio {
          font-size: 12px;
          font-weight: bold;
          white-space: nowrap;
        }

        .kda-split {
          color: #cddcfe;
          white-space: nowrap;

          .slash {
            color: #5f5f7b;
          }
        }

        .total-games {
          color: #cddcfe;
          text-align: right;
        }
      }

      .champion-col-center {
        justify-content: center;
      }
    }

    .show-more-btn {
      @include flex-center;
      height: 48px;
      border-radius: 0px 0px 3px 3px;
      background-color: #25254b;
      font-size: 12px;
      font-weight: 500;
      padding: 16px 0px;
      border-top: 1px solid #070720;

      &:hover {
        background-color: #383864;
        cursor: pointer;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:53";