.desktop-app-splash-page {
  position: relative;
  padding: 0 24px;
  width: 100%;
  font-family: "Inter";
  font-weight: 500;
  // background-image: url("https://static.bigbrain.gg/assets/ugg/pages/desktop-app/irelia-bg.png");
  // background-repeat: no-repeat;
  // background-position: 50% -50px;
  // background-size: calc(max(1920px, 100%));

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }

  .desktop-app-splash-page_width-container {
    margin: 0 auto;
    width: 1016px;
  }

  .desktop-app-splash-page_header {
    position: relative;
    margin: 66px 0 80px;

    h1 {
      font-family: "Barlow";
      color: #ffffff;
      text-transform: uppercase;
      filter: drop-shadow(0px 0px 20px #070720);

      span {
        font-size: 30px;
        font-weight: 400;
        line-height: 36px;
      }

      strong {
        font-size: 36px;
        font-weight: 700;
        line-height: 43px;
      }
    }

    h2 {
      font-family: "Inter";
      margin: 60px auto 24px;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: #cddcfe;
    }

    .download-row {
      gap: 18px;
    }
  }

  .video-bg {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    width: 923px;
    height: 520px;
    transform: translateX(-50%);

    .overlay {
      position: absolute;
      left: -3px;
      right: -3px;
      top: 0;
      bottom: 0;
      background-image: linear-gradient(270deg, #070720 0%, rgba(7, 7, 32, 0) 49.82%, #070720 100%),
        linear-gradient(180deg, rgba(7, 7, 32, 0) 0%, #070720 100%);
    }

    video {
      display: flex;
      width: 100%;
    }
  }

  .download-row {
    display: flex;
    gap: 24px;
    min-height: 40px;
  }

  .desktop-app-splash-page_features-container {
    margin-top: 60px;

    .features-container_header {
      position: relative;
      margin-bottom: 18px;
      padding-left: 14px;
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
        height: 20px;
        background: #3273fa;
        border-radius: 2px;
      }

      h1 {
        font-family: "Inter";
        font-weight: 700;
        font-size: 14px;
        color: #ffffff;
      }
    }

    .features-grid {
      display: flex;
      flex-wrap: wrap;
      width: 1014px;
      gap: 18px;
    }
  }

  .app-disclaimer {
    margin-top: 48px;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    color: #383864;
  }
}

;@import "sass-embedded-legacy-load-done:70";