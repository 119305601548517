.carousel-banner-container {
  position: relative;
  width: 100%;
  height: 1px;

  // .slide-container {
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   opacity: 0;
  //   transition: 400ms opacity ease-in-out;
  //   transition-delay: 400ms;

  //   &_active {
  //     z-index: 1;
  //     opacity: 1;
  //     transition-delay: 0ms;
  //   }
  // }

  // .app-banner {
  //   height: 100%;
  //   overflow: hidden;

  //   .app-preview {
  //     top: 0;
  //   }
  // }
}

;@import "sass-embedded-legacy-load-done:47";