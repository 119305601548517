.favorite-profile-button {
  background-color: #4f4f60;
  height: 36px;
  width: 90px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  align-items: center;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.5 ease-in-out;

  @media (max-width: 500px) {
    height: 30px;
  }

  &.is-fav {
    width: 36px;
  }

  img.favorite-star {
    width: 20px;
    height: 20px;
  }
}

.favorite-tooltip {
  font-weight: normal;
}

// .favorite-button {
// 	background-color: $volxd-tier;
// 	height: 36px;
// 	width: 90px;
// 	display: flex;
// 	justify-content: space-between;
// 	font-weight: bold;
// 	align-items: center;
// 	border-radius: 3px;
//   margin-top: 6px;
// 	cursor: pointer;
// 	transition: all .5 ease-in-out;
//
// 	font-size:12px;
//
// 	@media (max-width: 500px) {
// 		height: 30px;
// 	}
//
// 	&.is-fav {
// 		width: 36px;
// 	}
//
//   span {
//     margin-right: 12px;
//   }
//
//   .icon {
//     margin-top: 3px;
//     margin-left: 6px;
//
//     path {
//       fill: white;
//     }
//   }
// }

.favorite-tooltip {
  font-weight: normal;
}

;@import "sass-embedded-legacy-load-done:76";