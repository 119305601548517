.summoner-finder {
  position: relative;
  width: 100%;

  .summoner-finder_region-selector {
    position: absolute;
    top: 0;
    left: 1px;
    bottom: 0;
    display: flex;
    align-items: center;

    &.summoner-finder_region-selector__right {
      left: auto;
      right: 0;
      z-index: 1;
    }

    .select_modal:hover {
      background-color: transparent;
    }

    .region-selector-button {
      height: 36px !important;
      padding: 0 6px;
      border: none;
      background-color: transparent;

      .arrow-icon {
        svg {
          g,
          path {
            fill: #ffffff;
          }
        }
      }
    }
  }

  .custom-input-container {
    input {
      padding: 0 9px 0 78px;
    }
  }

  &.summoner-finder_white {
    .region-selector-button {
      height: 36px !important;
      padding: 0 6px;
      border: none;
      background-color: transparent !important;

      &:hover {
        background-color: #c9cad3 !important;
      }

      .arrow-icon {
        svg {
          g,
          path {
            fill: #626696;
          }
        }
      }
    }

    .custom-input-container {
      input {
        border: none;
        background-color: #ffffff;
        color: #5f5f7b;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:43";