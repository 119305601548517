.multisearch-main {
  width: 100%;
  max-width: 1058px;
  margin: 50px auto;

  @include responsive("TABLET") {
    margin: 24px auto;
    width: 100%;
  }

  .off-white {
    color: #cddcfe;
  }

  .champion-image-container {
    position: relative;
    @include flex-center;
    width: 12px;
    height: 12px;
    border-radius: 1px;
    overflow: hidden;

    img {
      position: absolute;
      width: 100%;
      flex: 0 0 100%;
      transform: scale(1.1);
    }
  }

  .empty-multisearch {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .bolded-text {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 24px;
      text-align: center;
    }
  }

  .multisearch-image {
    width: 100%;
  }

  .error-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
    font-size: 16px;

    img {
      margin-bottom: 15px;
      min-width: 50px;
      max-width: 200px;
    }
  }

  .loader-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .multisearch-search-container {
    margin-bottom: 24px;

    .search-header {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      .header-text {
        font-size: 14px;
        color: #cddcfe;
      }
    }

    .region-selector {
      margin-right: 12px;
      .region-selector-button {
        padding: 0 12px;
        background-color: #191937;
        border-radius: 3px;
      }
    }

    .textarea-container {
      position: relative;
    }

    textarea {
      font-family: "Helvetica Neue", "Roboto", Arial, Sans-Serif;
      padding: 12px;
      width: 100%;
      height: 126px;
      resize: none;
      border: 1px solid transparent;
      outline: none;
      border-radius: 3px;
      background-color: #191937;
      box-shadow: 0 2px 4px rgba(14, 37, 84, 0.22);
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;

      &:focus {
        border: 1px solid #3273fa;
      }

      &::placeholder {
        color: #7687ad;
      }
    }

    .textarea-placeholder {
      position: absolute;
      top: 12px;
      left: 12px;
      color: #7687ad;
      font-size: 14px;
      line-height: 20px;
      pointer-events: none;
    }

    .search-btn-row {
      display: flex;
      align-content: center;
      justify-content: flex-end;
      margin-top: 12px;

      .search-btn {
        width: 114px;
        height: 36px;
        border-radius: 3px;
      }
    }
  }

  $player-column-width: 202px;
  .multisearch-results {
    display: grid;
    grid-auto-flow: columns;
    grid-template-columns: repeat(5, $player-column-width);
    grid-gap: 12px;

    @include responsive("TABLET") {
      grid-template-columns: repeat(auto-fill, $player-column-width);
    }
    @include responsive("MOBILE_LARGE") {
      display: flex;
      overflow-x: auto;
    }
  }

  .multisearch-player {
    display: grid;
    padding: 12px 0;
    grid-gap: 12px 0;
    width: $player-column-width;
    flex: 0 0 $player-column-width;
    border-radius: 6px;
    background-color: #11112a;
    font-size: 11px;
    font-weight: 500;

    hr {
      width: 100%;
      border-color: #070720;
    }

    .backslash {
      color: #5f5f7b;
    }

    .player_historic-ranks {
      height: 42px;
      overflow: hidden;
      padding: 0 12px;
    }

    .player_info {
      @include flex-center;
      flex-direction: column;
      padding: 12px;
      margin: 0 12px;
      border-radius: 2px;
      background-color: #191937;
      text-align: center;
      overflow: hidden;

      .rank-image-container {
        @include flex-center;
        margin-bottom: 7px;
        width: 36px;
        height: 36px;

        img {
          flex: 0 0 100%;
          width: 100%;
        }
      }

      .summoner-name {
        width: 100%;
        font-family: "Barlow";
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
          text-decoration: underline;
        }
      }

      .current-rank {
        margin: 7px 0 6px;
        font-size: 12px;

        &_unranked {
          color: #5f5f7b;
        }

        .lp {
          color: #cddcfe;
        }
      }

      .current-games {
      }
    }

    .player_role-distribution {
      display: flex;
      justify-content: space-between;
      min-height: 73px;
      margin: 0 12px;
      gap: 12px;

      .role-container {
        @include flex-center;
        flex-direction: column;
        flex: 1;
        border-radius: 2px;
        background-color: #191937;

        &:only-child {
          margin: 0 auto;
        }

        .role-img {
          @include flex-center;
          margin-bottom: 8px;
          width: 16px;
          height: 16px;

          svg {
            flex: 0 0 16px;
            width: 16px;
          }
        }
      }
    }

    .player_streak {
      display: flex;
      justify-content: center;
      min-height: 24px;

      .game-streak {
        @include flex-center;
        padding: 6px 0;
        width: 100%;
        border: 1px solid transparent;
        background-color: #11112a;
        color: #ffffff;
        border-color: #070720;
        border-left: 0;
        border-right: 0;

        &_loss {
          background: #3b213b;
        }
        &_win {
          background: #1e2b5e;
        }
        &_gold-win {
          background: #885615;
        }
      }
    }

    .player_champion-performance {
      margin: 0 12px;

      .performance_title {
        margin-bottom: 6px;
        color: #ffffff;
      }
      .performance_list {
        display: grid;
        grid-template-rows: repeat(5, 20px);
        grid-gap: 4px;
        grid-auto-flow: row;

        .champion {
          display: grid;
          grid-template-columns: 52px 1fr 1fr;
          grid-auto-flow: column;
          padding: 4px 6px;
          border-radius: 1px;
          background-color: #191937;

          > * {
            display: flex;
            align-items: center;
            white-space: nowrap;

            &:nth-child(2) {
              justify-content: center;
            }

            &:last-child {
              justify-content: flex-end;
            }
          }

          .champion-image-container {
            margin-right: 4px;
          }
        }
      }
    }

    .player_recent-matches {
      margin: 0 12px;

      .recent-matches_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;

        .recent-matches_title {
          color: #ffffff;
          white-space: nowrap;
        }

        .recent-matches_roles {
          display: flex;
          align-items: center;

          .role {
            display: flex;
            align-items: center;
            margin-left: 12px;

            svg {
              margin-right: 4px;
              flex: 0 0 12px;
              width: 12px;
            }
          }
        }
      }

      .recent-matches_list {
        display: grid;
        grid-template-rows: repeat(10, 20px);
        grid-gap: 4px;
        grid-auto-flow: row;
        border-top: 1px #fff;

        .recent-matches_match {
          display: flex;
          align-items: center;
          padding: 0 6px;
          border-radius: 1px;
          background-color: #25254b;

          &__win {
            background: linear-gradient(to top, rgba(50, 115, 250, 0.18), rgba(50, 115, 250, 0.18));
          }

          &__loss {
            background: linear-gradient(to top, rgba(255, 78, 80, 0.18), rgba(255, 78, 80, 0.18));
          }

          .match-lp {
            margin-left: 8px;
            flex: 0 0 40px;
            width: 40px;
          }

          .kda {
            flex: 1;
            text-align: center;
          }

          .match-time {
            display: flex;
            justify-content: flex-end;
            flex: 0 0 20px;
            width: 20px;
          }
        }
      }
    }

    .match-lp-display {
      display: flex;
      align-items: center;

      .lp-arrow {
        width: 6px;
        flex: 0 0 6px;

        &.gain {
          path {
            fill: #3273fa;
          }
        }
        &.loss {
          path {
            fill: #ff4e50;
          }
        }
      }

      .lp-diff-container {
        @include flex-center;
      }

      .lp-gain_loss {
        display: flex;
        align-items: center;
      }

      .rank-up {
        display: flex;
        align-items: center;
        .lp-arrow {
          path {
            fill: #ffffff;
          }
        }
        .promoted-tier-img {
          margin-left: 2px;
          width: 16px;
          flex: 0 0 16px;
        }
        .promoted-rank {
          margin-left: 2px;
          color: #ffffff;
          font-weight: 700;
        }
      }

      .placement-progress {
        .current-placement-match {
          font-size: 18px;
          line-height: 1;
        }
        .total-placement-matches {
        }
      }

      .lp-value {
        margin-left: 4px;
        font-weight: 700;
        white-space: nowrap;
      }

      .no-diff {
        color: #5f5f7b;
        font-weight: 700;
      }

      .promos {
        .promo_game {
          width: 7px;
          height: 7px;
          border-radius: 50%;

          &:not(:last-child) {
            margin-right: 2px;
          }
        }
      }
    }
  }

  .promos {
    display: flex;
    flex-direction: column;
    align-items: center;

    .promo-progress {
      display: flex;
      align-items: center;
    }

    .promo_game {
      position: relative;
      display: flex;
      align-items: center;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #383864;

      &:not(:last-child) {
        margin-right: 3px;
      }

      &.promo_game__win {
        background-color: #3273fa;
      }
      &.promo_game__loss {
        background-color: #ff4e50;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:75";