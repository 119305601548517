#side-nav-container {
  position: fixed;
  top: 31px;
  bottom: 0;
  left: 0;
  z-index: 9999999;

  .masthead-ugg-search-bar {
    display: none;
  }
}

@include side-nav-breakpoint("sm") {
  .masthead-ugg-search-bar {
    display: flex;
    position: relative;
    z-index: 100;
    margin: 0 12px 18px;
  }
}

;@import "sass-embedded-legacy-load-done:95";