.page-notification {
  color: white;
  font-size: 18px;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 4px;

  .notification-title {
    font-weight: bold;
    font-size: 20px;
    margin: 0px 0px 10px 0px;
  }

  &.error {
    background-color: #45192b;
    border: 1px solid #ff4e50;
  }
}

;@import "sass-embedded-legacy-load-done:78";