.champion-recommended-build {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  max-width: 1014px;
  width: 100%;

  @include responsive("TABLET") {
    min-width: auto;
    max-width: unset;
    width: 100%;
  }

  .spinthatshit-loader {
    margin-top: 80px;
  }

  .wr-matches {
    white-space: nowrap;
    .matches {
      color: #cddcfe;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .rune-spell {
    margin-top: 18px;
  }

  .grid-1 {
    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-columns: minmax(550px, 1.3fr) minmax(0, 441px);

    @include responsive("MOBILE_LARGE") {
      grid-template-columns: 1fr 1fr;
    }
  }

  .recommended-build_runes {
    grid-row: 1 / 3;
    grid-column: 1 / 2;
    border-right: 1px solid #070720;
    background: linear-gradient(226.7deg, #313160 -134.31%, #191937 100%), #191937;

    .content-section_header {
      justify-content: space-between;

      .main-header {
        display: flex;
        padding-right: 24px;
        white-space: nowrap;
        line-height: 20px;
        overflow: hidden;
      }
    }

    .rune-trees-container {
      flex: 1;
    }

    @include responsive("MOBILE_LARGE") {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
      border-right: none;
      border-bottom: 1px solid #070720;

      .rune-trees-container {
        justify-content: space-evenly;

        .primary-tree {
          margin-right: 12px;
        }
      }
    }
  }

  .summoner-spells {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    border-bottom: 1px solid #070720;

    .content-section_header {
      justify-content: space-between;
    }

    img {
      width: 36px;
      height: 36px;
      border-radius: 3px;

      &:first-child {
        margin-right: 12px;
      }
    }

    @include responsive("MOBILE_LARGE") {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
      border: none;
    }
  }

  .toughest-matchups {
    flex: 1;
    border-radius: 3px;
    overflow: hidden;
    scrollbar-width: thin;
    scroll-padding: 10px;

    .matchups {
      display: flex;
      align-items: center;
      grid-gap: 12px;
      -webkit-overflow-scrolling: touch;
      overflow: auto;

      .champion-matchup {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        min-width: 85px;
        max-width: 90px;
        padding: 6px 0;
        border-radius: 3px;
        background-color: #11112a;
        color: #ffffff;
        text-align: center;

        &:hover {
          background-color: #25254b;
        }
      }

      hr {
        margin: 6px 0;
        width: 70%;
        border: none;
        border-top: 1px solid #404064;
      }

      .champion-name {
        margin-top: 6px;
        max-width: 70px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 12px;
        font-weight: bold;
      }

      .champion-face {
        position: relative;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;

        > img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          transform: translate(-50%, -50%) scale(1.1);
        }
      }

      .matchup-stats {
        .win-rate {
          font-size: 12px;
        }
        .total-matches {
          color: #cddcfe;
        }
      }
    }
  }

  .otp-stats-container {
    margin-top: 24px;
    grid-column: 2 / 4;
    @include responsive("TABLET") {
      grid-column: 1 / 2;
      grid-row: 3 / 4;
    }
    @include responsive("MOBILE_LARGE") {
      grid-column: 1 / 2;
      grid-row: 3 / 4;
    }

    .otp-stats {
      display: grid;
      align-items: center;
      grid-auto-flow: column;
      grid-auto-columns: 1fr; // play with this to change height of the children, 50% will fill half
      grid-template-columns: unset; // do not set template columns and rows
      grid-template-rows: unset;

      grid-gap: 12px;
      -webkit-overflow-scrolling: touch;
      overflow: auto;

      .no-otp-stats-message {
        @include flex-center;
        padding: 48px 24px;
        height: 100%;
        color: #cddcfe;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
      }

      .otp {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 100px;
        padding: 7px 0 10px;
        border-radius: 3px;
        background-color: #11112a;
        color: #ffffff;
        text-align: center;

        &:hover {
          background-color: #25254b;
        }
      }

      hr {
        margin: 8px 0;
        width: 70%;
        border: none;
        border-top: 1px solid #404064;
      }

      .summoner-name {
        margin-top: 6px;
        max-width: 70px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 11px;
        font-weight: bold;
      }

      .summoner-icon {
        position: relative;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;

        > img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          transform: translate(-50%, -50%) scale(1.1);
        }
      }
      .tier-img {
        position: relative;
        width: 17px;
        height: 17px;
        overflow: hidden;

        > img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          transform: translate(-50%, -50%) scale(1.1);
        }
      }
      .otp-detail {
        .tier {
          font-size: 12px;
          padding-top: 3px;
          padding-bottom: 3px;
        }
        .win-rate {
          font-size: 12px;
        }
        .total-matches {
          color: #cddcfe;
        }
        .region {
          display: inline-block;
          text-align: center;
          border-radius: 1%;
          //padding: 1px 0px 1px 0px;
          min-width: 27px;

          font-size: 10px;
          background-color: #414165;
          color: #ffffff;
        }
      }
    }
  }

  .recommended-build_skills {
    display: grid;
    grid-template-columns: 202px 1fr;
    margin-top: 12px;

    .skill-priority {
      grid-column: 1 / 2;
      border-right: 1px solid #070720;

      @include responsive("MOBILE_LARGE") {
        grid-column: 1 / 3;
        border-right: none;
        border-bottom: 1px solid #070720;
      }

      .skill-priority_content {
        width: max-content;

        @include responsive("MOBILE_MEDIUM") {
          width: auto;
        }

        .skill-priority-path {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 36px;
            height: 36px;
            border-radius: 3px;
          }

          .arrow-right {
            margin: 0 5px;
            width: 12px;
          }
        }

        .winrate {
          margin-bottom: 1px;
          font-size: 12px;
          font-weight: 700;
        }
        .matches {
          font-size: 10px;
          color: #cddcfe;
        }
      }
    }

    .skill-path-block {
      grid-column: 2 / 6;

      @include responsive("MOBILE_LARGE") {
        grid-column: 1 / 6;
      }

      .content-section_header {
        .main-header {
          flex: 1;
        }
      }

      .skill-path-container {
        overflow: auto;
      }

      .evolution-path {
        display: flex;
        align-items: center;
        border-radius: 3px;
        background-color: #11112a;

        .evolution-label {
          margin: 0 12px;
          color: #cddcfe;
          font-size: 11px;
          font-weight: 500;
        }

        .evolution-skill {
          flex: 0 0 24px;
          width: 24px;
          height: 24px;
          border-radius: 3px;
        }

        .arrow-right {
          margin: 0 6px;
          width: 10px;
        }
      }

      .mobile-evolution-path {
        display: inline-flex;
        margin-bottom: 12px;
      }
    }
  }

  .recommended-build_items {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-flow: column;
    margin-top: 12px;
    gap: 1px;
    background-color: #070720;

    @media (min-width: 600px) and (max-width: 1050px) {
      grid-template-columns: repeat(6, 1fr);
    }
    @include responsive("MOBILE_MEDIUM") {
      grid-template-columns: none;
      grid-template-rows: repeat(5, min-content);
      grid-auto-flow: row;
    }

    &__arena {
      grid-template-columns: repeat(6, 168px);

      @include responsive("DESKTOP_SMALL") {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: min-content 1fr;
        grid-auto-flow: row;

        * {
          grid-row: unset !important;
          grid-column: unset !important;
        }
      }

      @include responsive("MOBILE_MEDIUM") {
        grid-template-columns: none;
        grid-template-rows: repeat(6, min-content);
      }
    }

    .item-row {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .item-img {
      border-radius: 3px;
      width: 36px;
      height: 36px;
      overflow: hidden;
    }
    .item-dupe {
      position: relative;

      .count-label {
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translate(30%, 30%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #383864;
        font-size: 11px;
        font-weight: 700;
        pointer-events: none;
      }
    }

    .item-stats {
      white-space: nowrap;

      .winrate {
        font-size: 12px;
        font-weight: 700;
      }
      .matches {
        color: #cddcfe;
        font-size: 11px;
      }
    }

    .starting-items,
    .core-items,
    .item-options-1,
    .consumables {
      display: flex;
      flex-direction: column;

      .content-section_blurb {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex: 1;
        // white-space: nowrap;
      }
    }

    .starting-items {
      @media (min-width: 600px) and (max-width: 1050px) {
        grid-row: 1 / 2;
        grid-column: 1 / 4;
      }

      .item-dupe:not(:last-child) {
        margin-right: 6px;
      }

      .item-stats {
        margin-top: 14px;
        text-align: center;
      }
    }

    .core-items {
      position: relative;
      > * {
        z-index: 1;
      }
      &::before {
        content: "";
        position: absolute;
        z-index: 0;
        top: 0;
        bottom: 0;
        left: -1px;
        right: -1px;
        background: linear-gradient(45deg, #5c463b, #fbae02);

        @media (min-width: 600px) and (max-width: 1050px) {
          bottom: -1px;
          right: 0px;
        }
        @include responsive("MOBILE_MEDIUM") {
          bottom: -1px;
          left: 0px;
          right: 0px;
        }
      }
      &::after {
        content: "";
        position: absolute;
        z-index: 0;
        top: 1px;
        bottom: 1px;
        left: 0px;
        right: 0px;
        background-image: linear-gradient(225deg, #313160 0%, #191937 100%);

        @media (min-width: 600px) and (max-width: 1050px) {
          bottom: 0px;
          right: 1px;
        }
        @include responsive("MOBILE_MEDIUM") {
          bottom: 0px;
          left: 1px;
          right: 1px;
        }
      }

      @media (min-width: 600px) and (max-width: 1050px) {
        grid-row: 1 / 2;
        grid-column: 4 / 7;
        border-right: none;
      }

      .path-arrow {
        flex: none;
        width: 12px;
      }
      .item-stats {
        margin-top: 14px;
        text-align: center;
      }
    }

    .item-options {
      @include responsive("MOBILE_MEDIUM") {
        .item-option-list {
          display: flex;
          justify-content: center;

          .item-option {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 0 0 85px;
            margin: 0;

            &:not(:last-child) {
              margin-right: 12px;
            }

            .item-stats {
              margin-left: 0;
              margin-top: 14px;
              text-align: center;
            }
          }
        }
      }

      &.item-options-1 {
        @media (min-width: 600px) and (max-width: 1050px) {
          grid-row: 2 / 3;
          grid-column: 1 / 3;
        }
      }
      &.item-options-2 {
        @media (min-width: 600px) and (max-width: 1050px) {
          grid-row: 2 / 3;
          grid-column: 3 / 5;
        }
      }
      &.item-options-3 {
        @media (min-width: 600px) and (max-width: 1050px) {
          grid-row: 2 / 3;
          grid-column: 5 / 7;
        }
      }

      .item-option {
        display: flex;
        align-items: center;
        margin-right: 12px;
        margin-bottom: 12px;

        &:last-child {
          margin-right: 0;
          margin-bottom: 0;
        }

        .item-stats {
          margin-left: 12px;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:74";