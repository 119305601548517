.leaderboard_pagination {
  margin-top: 24px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .pagination-nav {
    @include flex-center;

    .pagination-nav_pages {
      @include flex-center;
      gap: 4px;

      .page-link {
        position: relative;
        padding: 2px 6px;
        font-size: 14px;
        font-weight: 500;
        color: #cddcfe;

        &.page-link_is-current,
        &:hover {
          cursor: pointer;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            border-radius: 1px;
            background-color: #3273fa;
          }
        }
      }
    }
  }

  .skip-to-container {
    display: flex;
    align-items: center;

    &:empty {
      display: none;
    }
    &_left {
      margin-right: 12px;
    }
    &_right {
      margin-left: 12px;
    }

    .skip-to {
      white-space: nowrap;
      font-size: 14px;
      font-weight: 700;
      color: #ffffff;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }

      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }

  .total-rows {
    @include flex-center;
    margin-top: 10px;
    font-size: 12px;
    color: #ffffff;

    .current-rows {
      .dash {
        color: #5f5f7b;
      }
    }

    .out-of {
      margin-left: 10px;

      .label {
        color: #cddcfe;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:84";