.not-found-user-summoner-profile {
  width: 100%;

  .error-dialogue-box {
    align-self: unset;
    margin-top: 24px;
  }

  &.not-found_loading {
    display: flex;
    align-items: center;
    height: calc(100vh - #{$masthead-height});
  }

  .not-found_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .rengar-img {
      margin: 30px 0;
      width: 200px;
    }

    .header {
      color: #ffffff;
      font-size: 36px;
      font-weight: 700;
      text-align: center;

      @include responsive("MOBILE_MEDIUM") {
        font-size: 18px;
      }
    }

    .sub-text {
      margin-top: 8px;
      color: #cddcfe;
      font-size: 24px;
      text-align: center;

      @include responsive("MOBILE_MEDIUM") {
        font-size: 16px;
      }
    }

    .summoner-name-input-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      width: 100%;

      .summoner-finder {
        width: 100%;
        max-width: 600px;

        .region-selector-button {
          padding: 0 12px;
          height: 56px !important;
          transform: none;

          .region-selector-item img {
            width: 40px !important;
          }
        }

        .custom-input-container {
          input {
            padding: 0 20px 0 90px;
            height: 56px;
            border-radius: 4px 0 0 4px;
            font-size: 20px;
            font-weight: 400;
            outline: none;

            @include responsive("MOBILE_MEDIUM") {
              padding-right: 48px;
              border-radius: 4px;
            }
          }
        }

        .region-selector-modal {
          top: 62px;
        }
      }

      .save-settings-btn {
        flex: none;
        width: 150px;
        height: 56px;
        border-radius: 0 4px 4px 0;
        font-size: 16px;
        font-weight: 700;

        &.btn-success_disable {
          opacity: 0.5;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:81";