.premium-options-container {
  font-size: 22px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;

    .header-text {
      background: linear-gradient(
        180deg,
        #ffffff 0%,
        #e4c692 28%,
        #dead5c 68%,
        #daa346 100%
      ); // background color masked along the text and clip away the rest
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      font-family: "Barlow";
      font-size: 80px;
      font-weight: 600;
      text-transform: uppercase;

      &.light-gold {
        font-family: "Barlow";
        font-size: 100px;
        font-weight: 300;
      }

      &.bold-gold {
        font-family: "Barlow";
        font-size: 100px;
        font-weight: 600;
      }
    }
  }

  .subheader-text {
    color: #cddcfe;
    font-family: "Barlow";
    font-size: 48px;
    font-weight: 300;
    font-weight: 300;
    margin-bottom: 50px;
  }

  .upsell-text {
    font-size: 24px;
    margin-bottom: 60px;
  }

  .see-plans-button {
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    background-color: #08a6ff;
    border-radius: 2px;
    width: 180px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    margin-bottom: 130px;

    &:hover {
      background-color: lighten(#08a6ff, 5%);
    }
  }

  .img-divider {
    margin-bottom: 50px;
  }

  .features-text {
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 60px;
  }

  .pick-your-premium {
    color: white;
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 60px;
  }

  .terms-and-conditions {
    color: #5f5f7b;
    font-size: 12px;
    margin-bottom: 20px;
  }

  .faq-text {
    color: white;
    font-size: 12px;
  }
}

.features-container {
  display: flex;
  margin-bottom: 105px;
  width: 700px;

  .premium-checkout & {
    width: 620px;
  }

  &.no-bot-margin {
    margin-bottom: 0px;
  }

  .features-element {
    display: grid;
    grid-template-rows: 100px 73px;
    // height: 175px;
    width: 250px;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // align-items: center;

    .premium-checkout & {
      grid-template-rows: 79px 58px;
    }

    .icon-container {
      grid-row: 1 / 2;
      // height: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .element-img {
      width: 75px;
      height: 75px;
      position: relative;
      top: 12.5px;

      &.large {
        width: 75px;
        height: 100px;
        top: 0px;
      }
    }

    .text-container {
      grid-row: 2 / 3;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      .first-element-text {
        margin-bottom: 6px;
        color: white;
        font-size: 18px;
        font-weight: 700;

        .premium-checkout & {
          font-size: 14px;
        }
      }

      .second-element-text {
        color: #cddcfe;
        font-size: 16px;

        .premium-checkout & {
          font-size: 12px;
        }
      }
    }
  }
}

.premium-plan-boxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1020px;
  margin-bottom: 50px;

  .basic-premium-box {
    width: 320px;
    height: 275px;
    background-color: #17172e;
    border: 1px solid #2c2c40;
    border-radius: 2px;
  }

  .popular-premium-box {
    width: 320px;
    height: 370px;
    background-color: #17172e;
    background-image: linear-gradient(180deg, rgba(23, 23, 46, 0.83) 0%, #17172e 80%),
      linear-gradient(180deg, #ffffff 10%, #e4c692 28%, #dead5c 68%, #daa346 100%);
    border-radius: 2px;
    border: 1px solid #ff9b00;
  }

  .elements-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    position: relative;

    .months-text {
      font-size: 18px;
      color: #cddcfe;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 30px;
    }

    .price-text {
      font-size: 36px;
      font-weight: 700;
      color: #ffffff;

      .faded {
        color: #2c2c40;
      }
    }

    .divider {
      width: 80%;
      border-top: 0.25px solid rgba(256, 256, 256, 0.5);
      margin-top: -5px;
      margin-bottom: -5px;
    }

    .bottom-text-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .free-text {
        color: #cddcfe;
        font-size: 14px;
      }

      .billed-text {
        color: white;
        font-weight: 700;
        font-size: 14px;
      }
    }

    .try-button {
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;

      background-color: #31334a;
      border-radius: 2px;
      width: 275px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 20px;

      &:hover {
        background-color: lighten(#31334a, 5%);
      }

      &.blue {
        background-color: #08a6ff;
        &:hover {
          background-color: lighten(#08a6ff, 5%);
        }
      }
    }

    .popular-text {
      font-family: "Barlow";
      font-size: 30px;
      font-weight: 600;
      color: #dca953;
      margin-top: 80px;
      margin-bottom: -30px;
    }

    .popular-hex {
      position: absolute;
      top: -26.5px;
    }

    .unpopular-hex {
      position: absolute;
      top: -14px;
      right: 0px;
    }
  }
}

.landing-page-premium-container2 {
  // position: relative;
  background-color: #070720;
  width: 1012px;
  height: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 0px;
  border: solid 1px #daa346;
  cursor: pointer;

  .img-background-container {
    position: absolute;
    pointer-events: none;
    padding: 12px 12px 0px 12px;
  }

  .header-text {
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      #e4c692 28%,
      #dead5c 68%,
      #daa346 100%
    ); // background color masked along the text and clip away the rest
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: "Barlow";
    font-size: 80px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 20px 0px 2px;
  }

  .subheader-text {
    font-family: "Barlow";
    color: #cddcfe;
    font-weight: 300;
    font-size: 48px;
    margin-bottom: 50px;
  }

  .description-text {
    color: white;
    font-size: 24px;
    margin-bottom: 60px;
  }

  .see-plans-button {
    color: white;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin-bottom: 30px;

    background-color: #08a6ff;
    border-radius: 2px;
    width: 180px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 700;

    &:hover {
      background-color: lighten(#08a6ff, 5%);
    }
  }
}

.landing-page-premium-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 135px;
  margin-top: 70px;

  .title-container {
    display: flex;
    background-image: linear-gradient(180deg, #ffffff 0%, #e4c692 28%, #dead5c 68%, #daa346 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 15px;
    white-space: nowrap;
    .light-gold {
      font-family: "Barlow";
      font-size: 85px;
      font-weight: 300;
    }

    .bold-gold {
      font-family: "Barlow";
      font-size: 85px;
      font-weight: 600;
    }
  }

  .white-text {
    font-family: "Barlow";
    font-size: 48px;
    font-weight: 300;
    margin-bottom: 40px;
  }

  .learn-more-button {
    color: white;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    width: 200px;
    height: 50px;
    border-radius: 3px;
    background-color: $ugg-light-blue;
    font-size: 20px;
    font-weight: 700;

    &:hover {
      background-color: lighten($ugg-light-blue, 5%);
    }
  }
}

;@import "sass-embedded-legacy-load-done:80";