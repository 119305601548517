.summoner-tag {
  font-family: "Inter";
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: default;

  // &.great {
  //   color: #FF9B00;
  //   background-color: #513929;

  //   &.has-border {
  //     border: 1px solid #FF9B00;
  //   }
  // }

  &.great,
  &.good,
  &.positive {
    color: #3273fa;
    background-color: #18275e;

    &.has-border {
      border: 1px solid #3273fa;
    }
  }

  &.negative {
    color: #ff4e50;
    background-color: #47233c;

    &.has-border {
      border: 1px solid #ff4e50;
    }
  }

  &.pro {
    color: #3273fa;
    background-color: #18275e;

    &.has-border {
      border: 1px solid #3273fa;
    }
  }

  &.neutral {
    color: #cddcfe;
    background-color: #383864;

    &.has-border {
      border: 1px solid #383864;
    }
  }

  &.large {
    padding: 4px 6px;
    font-size: 14px;
    font-weight: 500;
  }

  &.medium {
    padding: 2.5px 5px;
    font-size: 11px;
    font-weight: 500;
  }

  &.small {
    padding: 0px 5px;
    font-size: 11px;
    font-weight: 500;
  }
}

;@import "sass-embedded-legacy-load-done:22";