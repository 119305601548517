.render-suggestion {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;

  .champion-icon {
    div {
      transform: scale(1.15);
    }
  }

  img.suggest {
    margin-right: 5px;
    height: 40px;
  }

  img.page-type {
    position: absolute;
    width: 16px;
    height: 16px;
    top: -8px;
    left: -8px;
    // Removed this in update
    display: none;
  }

  img.summoner-icon {
    width: 20px;
    height: 20px;
    border-radius: 2px;
  }

  .suggestion-image {
    width: 24px;
    height: 24px;
  }

  div.region-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    background-color: $ugg-light-blue;
    color: #ffffff;
    font-size: 10px;
    font-weight: bold;
  }

  .suggest-text {
    margin-left: 8px;
    color: #5f5f7b;
    font-size: 14px;
    font-weight: 400;
    font-family: "Inter";
    line-height: 17px;
  }

  .suggest-text-secondary {
    margin-left: 8px;
    color: #9d9db0;
    font-weight: 500;
    font-size: 10px;
    font-weight: 500;
    font-family: "Inter";
    line-height: 20px;
  }

  .suggest-tag-container {
    display: flex;
    gap: 4px;
    margin-left: auto;
    margin-right: -2px;

    .tag {
      height: 18px;
      border-radius: 6px;
      padding: 2px 4px 2px 4px;
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;
    }

    &.build {
      .ranked {
        background-color: #eaeeb233;
        color: #eaeeb2;

        .large-search & {
          background-color: #a4584e;
          color: #ffffff;
        }
      }

      .aram {
        background-color: #18f8eb33;
        color: #18f8eb;

        .large-search & {
          background-color: #25acd6;
          color: #ffffff;
        }
      }

      .arena {
        background-color: #3273fa33;
        color: #3273fa;

        .large-search & {
          background-color: #3273fa;
          color: #ffffff;
        }
      }

      .counters {
        background-color: #cddcfe33;
        color: #cddcfe;

        .large-search & {
          background-color: #5c5c8e;
          color: #ffffff;
        }
      }
    }

    &.pages {
      .tag {
        background-color: #cddcfe33;
        color: #cddcfe;
        opacity: 0.25;

        &.active {
          opacity: 1 !important;
        }

        .large-search & {
          background-color: #5c5c8e;
          color: #ffffff;
          opacity: 0.25;
        }
      }
    }
  }

  .suggest-right {
    margin-left: auto;
    margin-right: -2px;
    border-radius: 1px;
  }
}

.autosuggest-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  // color: #4E4F60;

  .autosuggest-button {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; // border: 1px solid #aaa;
    border: none;

    svg {
      // margin-top: 2px;
    }
  }

  .autosuggest-button:hover {
    // background-color: darken(white, 0.9);
    cursor: pointer;
  }

  ::placeholder {
    color: #8a8ebb;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
}

.react-autosuggest__container {
  // color: black;
  position: relative;
}

.react-autosuggest__input {
  width: 500px;
  height: 21px;
  padding: 10px 110px;
  font-family: "Inter";
  font-weight: 300;
  font-size: 12px;
  border: 1px solid #aaa;
  border-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none;
  border: none;
  color: #a9acd1;

  @include responsive("TABLET") {
    padding: 10px 110px 10px 12px;
  }
}

.react-autosuggest__input:focus,
.react-autosuggest__input:focus-visible,
.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  z-index: 10;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  z-index: 2;
  // top: 30px;
  width: 500px;
  background-color: #ffffff;
  color: #5f5f7b;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  max-height: 500px;
  overflow-y: auto;
}

.react-autosuggest__suggestions-list {
  margin: 4px 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 4px 8px;
}

.react-autosuggest__suggestion--highlighted {
  // background-color: #ddd;
  background-color: #e7eeff;
}

.react-autosuggest__section-title {
  background-color: #f4f7ff;
  padding: 8px;
  color: #383864;
  font-size: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  text-align: left;

  strong {
    font-weight: 600;
  }
}

$landing-search-height: 60px;

.large-search {
  .react-autosuggest__container {
    flex: 1;

    &.react-autosuggest__container_placeholder {
      height: $landing-search-height;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      background-color: #ffffff;
    }
  }

  .react-autosuggest__input {
    padding: 10px 60px 10px 18px;
    width: calc(100% - 36px);
    height: $landing-search-height;
    border: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    // border-radius: 30px 30px 30px 30px;
    border-radius: 3px;
    background-color: white;
  }
  .react-autosuggest__input::placeholder {
    // background-color: red;
    // color: blue;
  }

  .autosuggest-container {
    position: relative;
    height: $landing-search-height;

    .autosuggest-button {
      svg {
        // fill: white !important;
        g,
        path {
          // fill: white;
        }
      }
    }
    .modal-filter {
      // position: absolute;
      // top: 0;
      // left: 0px;

      .select_modal {
        .select_modal__toggle {
          border: 0px;
        }
      }

      &:hover {
        .show-all-container {
          background-color: darken(#f4f7ff, 0.9);
          border-radius: 30px 0px 0px 30px;
        }
      }

      .region-selector-button {
        height: 60px !important;
        border-radius: 30px 0px 0px 30px !important;
        background-color: #f4f7ff !important;
        width: 100px;

        &:hover {
          // background-color: rgba(255, 255, 255, 0.7) !important
          background-color: darken(#f4f7ff, 0.9) !important;
        }

        .arrow-icon svg {
          g,
          path {
            fill: $ugg-sub-grey;
          }
        }
      }

      .select_modal__modal-window.region-selector-modal {
        top: $landing-search-height;
        left: 20px;
      }
    }
  }

  .autosuggest-button {
    height: 100%;
    border-radius: 3px;
    width: 51px;

    div {
      pointer-events: none;
    }
    svg {
      height: 30px;
      width: 30px;
      pointer-events: none;
    }
  }

  .region-search {
    display: flex;
    position: relative;
    align-items: center;
    left: 1px;

    svg {
      height: 24px;
      width: 24px;
      right: 20px;
      z-index: 2;
      position: relative;
      margin-left: 31px;
    }
  }

  .react-autosuggest__suggestions-container--open {
    width: calc(100% + 47px);
    top: $landing-search-height;
    margin-top: 12px;
    font-size: 14px;
  }

  .react-autosuggest__section-title {
    font-size: 10px;
  }
}

.autosuggest-container {
  .modal-filter__open {
    .region-selector-button {
      // background-color: rgba(255, 255, 255, 0.7) !important
      background-color: #b1b3bb !important;
    }
  }

  .region-selector-button {
    height: 44px !important;
    background-color: #f4f7ff !important;
    border-radius: 3px 0px 0px 3px;

    &:hover {
      // background-color: rgba(255, 255, 255, 0.7) !important
      background-color: #b1b3bb !important;
    }

    .arrow-icon svg {
      g,
      path {
        fill: $ugg-sub-grey;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:29";