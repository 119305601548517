#side-nav-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9999999;
  font-family: "Inter";
  display: flex;
  flex-direction: column;
  margin-top: 65px;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);

  &.side-nav_theme__light {
    box-shadow: 1px 0px 4px rgba(14, 37, 84, 0.22);
  }

  &.side-nav_mobile {
    box-shadow: none;
  }
}

.side-nav_divider-container {
  background-color: #0d0d28;

  &.margin-top {
    margin-top: 18px;
  }
  &.margin-bottom {
    margin-bottom: 18px;
  }

  @include side-nav-light-theme {
    background-color: #ebf0fd;
  }

  .side-nav_divider {
    height: 1px;
    margin: 0 12px;
    background-color: #191937;

    @include side-nav-light-theme {
      background-color: #dbdfef !important;
    }
  }
}

.side-nav_head {
  position: absolute;
  top: -65px;
  width: 100%;
  height: 65px;

  .side-nav_head__outer {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    width: 100%;
    background: #0d0d28;
    transition: width $side-nav-transition-duration;
    overflow: hidden;

    @include side-nav-light-theme {
      background-color: #ebf0fd;
    }
  }

  .side-nav_head__inner {
    position: absolute;
    display: inline-flex;
    align-items: center;
    padding: 0 22px;
    min-height: 64px;
    height: 100%;
    background: #0d0d28;
    overflow: hidden;

    @include side-nav-light-theme {
      background-color: #ebf0fd;
    }
  }

  @include side-nav-light-theme {
    background-color: #ebf0fd;
  }

  .side-nav_ugg-logo {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      height: 26px;
    }
  }
}

.side-nav {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  transition: width $side-nav-transition-duration;
  background: #0d0d28;

  .side-nav_collapsed & {
    width: $side-nav-collapsed-width;
  }

  .side-nav_expanded & {
    width: $side-nav-expanded-width;
  }

  @include side-nav-light-theme {
    background-color: #ebf0fd;
  }

  .area-to-expand {
    position: absolute;
    top: 0;
    left: 0;
    height: 66px;
    width: $side-nav-collapsed-width;
    cursor: pointer;
  }

  .masthead-ugg-search-bar {
    position: relative;
    z-index: 100;
    margin: 0 12px 18px;

    .side-nav_collapsed.side-nav_mobile & {
      overflow: hidden;
    }
  }
}

;@import "sass-embedded-legacy-load-done:90";