.custom-input-container {
  width: 100%;

  input {
    padding: 0 9px;
    width: 100%;
    height: 36px;
    border-radius: 3px;
    border: 1px solid var(--input-border-color);
    background-color: $input-bg;
    background-color: var(--input-bg);
    color: var(--font-color-main);
    // caret-color: var(--ugg-common-blue);
    font-size: 12px;
    font-weight: 500;

    &::placeholder {
      color: $grey-text;
    }
  }

  .input-label-container {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .right-label {
      color: #3273fa;
      font-size: 14px;
      font-size: 500;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .input-error-msg {
    margin-bottom: 3px;
    color: $ugg-red;
    font-size: 14px;

    &.input-error-msg_hide {
      display: none;
    }
  }

  .input-error-msg ~ div > input {
    border-color: $ugg-red;
  }
}

.dob-form {
  width: 100%;

  .dob-errors {
    color: $ugg-red;

    .dob-error {
      margin-bottom: 3px;
      font-size: 12px;
    }
  }

  .dob-label {
    margin-bottom: 3px;
    color: $grey-text;
  }

  .dob-dropdowns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 264px;

    .default-select {
      min-width: auto;

      &.filter-select_month {
        width: 120px;
      }
      &.filter-select_day {
        width: 58px;
      }
      &.filter-select_year {
        width: 65px;
      }

      .Select-menu {
        max-height: 300px;
        overflow-y: auto;
      }

      .Select-control {
        padding: 0 8px;

        .Select-multi-value-wrapper {
          justify-content: flex-start;

          .Select-value {
            justify-content: flex-start;
            padding: 0;
          }
        }

        .Select-arrow-zone {
          display: inline-block;

          .dropdown-arrow {
            width: 10px;
          }
        }
      }
    }
  }
}

.start-date {
  .filter-select {
    min-width: auto;

    &.filter-select_month {
      flex: 0 0 45%;
    }
    &.filter-select_year {
      flex: 0 0 45%;
    }

    .Select-menu {
      max-height: 300px;
      overflow-y: auto;
    }

    .Select-control {
      padding: 0 8px;

      .Select-multi-value-wrapper {
        justify-content: flex-start;

        .Select-value {
          justify-content: flex-start;
          padding: 0;
        }
      }

      .Select-arrow-zone {
        display: inline-block;

        .dropdown-arrow {
          width: 10px;
        }
      }
    }
  }
}

.password-input_with-forgot-pw {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 12px;

  input {
    padding-right: 115px;
  }

  a {
    position: absolute;
    display: flex;
    align-items: center;
    top: 50%;
    right: 9px;
    height: 100%;
    transform: translateY(-50%);
    opacity: 0.7;
    color: var(--ugg-common-blue);

    &:hover {
      opacity: 1;
    }
  }
}

.password-checker {
  display: flex;
  flex-direction: column;

  .checklist-item {
    display: flex;
    align-items: center;

    &.checklist-item_checked {
      color: #ffffff;
    }
    &.checklist-item_unchecked {
      color: #ffffff;
    }

    .checklist-item_icon {
      width: 12px;
    }

    .checklist-item_label {
      margin-left: 10px;
      font-size: 13px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:42";