.region-selector-modal {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 45px;
  width: 230px;
  border-radius: 3px;
  border: 1px solid var(--border-color-grey);
  background-color: var(--filter-color);
  box-shadow: 0px 2px 8px -2px rgba(14, 37, 84, 0.22);
  font-size: 12px;
  color: var(--font-color-main);
}

.modal-filter__open {
  .region-selector-button {
    background-color: var(--filter-color);
  }
}

.region-selector-button {
  position: relative;
  padding: 0 10px;
  border: 3px 0px 0px 3px;
  background-color: #f4f7ff;

  .region-selector-input {
    position: absolute;
    top: -99999px;
    left: -99999px;
  }

  .arrow-icon svg {
    width: 10px;
    margin-left: 10px;
  }
  &:hover {
    background-color: var(--filter-option-selected) !important;
  }
}

// &.na1 {
//   background: #ff9b00;
// }
// &.euw1 {
//   background: #1e48a8;
// }
// &.eun1 {
//   background: #3374a9;
// }
// &.kr {
//   background: #4d2ac0;
// }
// &.br1 {
//   background: #6cd7a2;
// }
// &.jp1 {
//   background: #ec5d8c;
// }
// &.ru {
//   background: #bf2828;
// }
// &.oc1 {
//   background: #8eced5;
// }
// &.tr1 {
//   background: #ec5c57;
// }
// &.la1 {
//   background: #f7c544;
// }
// &.la2 {
//   background: #ed642b;
// }
// &.ph2 {
//   background: #576bce;
// }
// &.sg2 {
//   background: #2b90ed;
// }
// &.th2 {
//   background: #af893d;
// }
// &.tw2 {
//   background: #00c280;
// }
// &.vn2 {
//   background: #145a66;
// }
// &.me1 {
//   background: #7d6641;
// }

;@import "sass-embedded-legacy-load-done:77";