.ugg-tooltip {
  z-index: 9999999;
  position: absolute;
  // min-width: 100px;
  max-width: 400px;
  padding: 10px 12px;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  background-color: #0b0b23;
  border: 1px solid #4f4f60;
  border-radius: 3px;
  box-shadow: 0 2px 4px #070720;
  will-change: transform;

  &.tooltip-transition {
    &-enter-done {
      opacity: 1 !important;
      transform: rotate(360deg) translateZ(0px) scale(1) !important;
    }
  }

  font[size] {
    font-size: inherit;
  }

  > div {
    .arrow {
      z-index: 99;
      position: absolute;
      // left: 50%;
      width: 13px;
      height: 13px;
      border-left: 1px solid #4f4f60;
      border-bottom: 1px solid #4f4f60;
      background-color: #0b0b23;
    }
    .arrow-up {
      top: -7px;
      transform: translateX(-50%) rotate(135deg);
    }
    .arrow-down {
      bottom: -7px;
      transform: translateX(-50%) rotate(-45deg);
    }
    .arrow-left {
      left: -7px;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }

    .arrow-right {
      right: -7px;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }
  }
}

stats {
  // color: green;
}

unique {
  font-weight: 500;
}

.cost {
  color: #ff9b00;
}

.tooltip-champion-skill,
.tooltip-summoner-spell,
.tooltip-rune,
.tooltip-item,
.tooltip-arena-augment {
  background-color: #0b0b23;
  // padding: 10px;
  word-break: break-word;

  .name {
    font-size: 14px;
    font-weight: 500;
    color: #3273fa;
  }

  .cooldown {
    color: #92929d;
  }
}

.no-data-message {
  color: #ff9b00;
}

;@import "sass-embedded-legacy-load-done:98";