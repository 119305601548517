%btn-common,
.btn-common {
  @extend %flex-center;
  padding: 0 15px;
  min-width: 78px;
  min-height: 36px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}

.btn-box-shadow-hover:hover {
  opacity: 1;
  box-shadow: 0px 11px 8px -5px rgba(7, 7, 2, 0.3);
  cursor: pointer;
}

.btn-round {
  height: 60px;
  border-radius: 30px;
}

.btn-red {
  @extend %btn-common;
  border-color: $ugg-red;
  background-color: $ugg-red;
  transition: all 0.3s;

  &.btn-red_dim {
    border-color: #541b30;
    background-color: #541b30;
  }

  &.btn-red_hover:hover {
    opacity: 1;
    border-color: darken($ugg-red, 4%);
    background-color: darken($ugg-red, 4%);
  }
}

.btn-light-blue {
  @extend %btn-common;
  border-color: var(--ugg-common-blue);
  background-color: var(--ugg-common-blue);
}

.btn-blue {
  @extend %btn-common;
  border-color: #3273fa;
  background-color: #3273fa;

  &[data-disabled="true"] {
    border-color: #bbbedb;
    background-color: #bbbedb;
    pointer-events: none;
  }

  &.btn-blue_hover:hover {
    opacity: 1;
    border-color: #2963da;
    background-color: #2963da;
  }

  &.btn-box-shadow-hover:hover {
    background-color: #2963da;
  }
}

.btn-gray {
  @extend %btn-common;
  border-color: #31334a;
  background-color: #31334a;
}

.btn-dark {
  @extend %btn-common;
  border-color: #1c1c32;
  background-color: #1c1c32;

  &.btn-box-shadow-hover:hover {
    background-color: #131324;
  }
}

.btn-transparent {
  @extend %btn-common;
  border-color: var(--border-color-grey);
  color: var(--font-color-main);
}

.btn-success {
  position: relative;
  transition: background-color 0.3s, border-color 0.3s;
  overflow: hidden;

  .btn-label,
  .btn-loader {
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.3s transform 0.3s;
  }

  .btn-loader {
    width: 20px;
  }

  &.btn-success_loading {
    pointer-events: none;
  }

  &.btn-success_disable {
    opacity: 0.5;
    pointer-events: none;
  }

  &.btn-success_toggle {
    border-color: $ugg-light-green;
    background-color: $ugg-light-green;
    pointer-events: none;

    .btn-label,
    .btn-loader {
      opacity: 0;
      transform: scale(0.2);
    }

    .btn-checkmark {
      top: 50%;
      transform: scale(1) translateY(-50%);
      opacity: 1;
      transition: opacity 0.3s, transform 0.3s;
    }
  }

  .btn-checkmark {
    position: absolute;
    top: 50%;
    width: 20px;
    transform: scale(0.2) translateY(-50%);
    transform-origin: center;
    opacity: 0;
  }
}

.download-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  padding-left: 10px;
  min-width: 120px;
  min-height: 40px;
  border-radius: 3px;

  .windows-icon,
  .apple-icon {
    margin-right: 11px;
    width: 16px;
  }

  span {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
  }
}

;@import "sass-embedded-legacy-load-done:27";