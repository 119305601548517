.app-crash-error-page {
  position: relative;
  display: flex;
  flex-direction: column;

  .error-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 130px;
    padding-bottom: 36px;
  }

  .error-console {
    margin-top: 40px;
    padding: 12px 12px 12px 0px;
    border-radius: 6px;
    border: 1px solid grey;
    font-size: 12px;
    overflow-y: auto;

    pre {
      user-select: text;
    }

    @include clean-scrollbar(14px, transparent, 0px, #cddcfe, 7px, 4px);
  }

  .error-image {
    margin-bottom: 36px;
    width: 350px;
  }

  .error-message {
    font-family: "Barlow";
    font-size: 18px;
    font-weight: 500;
    color: #cddcfe;
    text-align: center;

    .spinthatshit-loader {
      margin-top: 40px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:85";