.recover-account-page {
  max-width: 500px;
  width: 95%;
  background-color: #11112a;
  border-radius: 6px;
  padding: 32px;

  @include responsive("MOBILE_MEDIUM") {
    padding: 24px;
  }

  .divider {
    height: 1px;
    background-color: #bbbedb;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  h1 {
    margin: 0;
    font-size: 30px;
    font-weight: 500;
    font-style: "Barlow";
    align-self: flex-start;

    @include responsive("MOBILE_MEDIUM") {
      font-size: 24px;
    }
  }

  p {
    margin-top: 12px;
    margin-bottom: 0;
    font-size: 16px;
    color: #cddcfe;

    @include responsive("MOBILE_MEDIUM") {
      font-size: 14px;
    }

    &.error-msg {
      font-size: 18px;
      font-weight: bold;
      color: $ugg-red;
    }
  }

  .recover-account-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
  }

  .custom-input-container {
    margin: 25px 0;

    input {
      height: 42px;
    }
  }

  .email-submit {
    width: 100%;
    height: 42px;
    font-size: 14px;
  }

  .email-sent-confirmation {
    .cta-buttons {
      display: flex;
      margin-top: 24px;

      > div:not(:last-child) {
        margin-right: 12px;
      }
    }
  }

  .logo-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 50px;

    @include responsive("MOBILE_MEDIUM") {
      margin-top: 30px;
    }
  }
}

.logo {
  width: 200px;
  cursor: pointer;
}

;@import "sass-embedded-legacy-load-done:41";