#masthead {
  &.landing {
    transition: all 0.3s ease;

    &.transparent {
      background-color: transparent;
      border-bottom: 1px solid transparent;
    }
  }

  &.verification-bar-active {
    top: $user-verification-bar-height;
    .masthead-container {
      top: $user-verification-bar-height;
    }
  }
}

#masthead {
  height: calc($masthead-height - 1px);
  margin-top: 31px;
  &.is-landing {
    height: 0;
  }

  @include responsive("MOBILE_LARGE") {
    &.is-landing {
      height: calc($masthead-height - 1px);
    }
  }

  .masthead-container {
    font-size: 14px;
    position: fixed;
    top: 31px;
    right: 0;
    width: calc(100% - 64px);
    padding: 0 24px;
    height: $masthead-height;
    background-color: #070720;
    color: $ugg-white;
    z-index: 9999999;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid #0d0d28;

    @include responsive("TABLET") {
      width: 100%;
      padding: 0 18px;
    }

    &.verification-bar-active {
      top: $user-verification-bar-height;
    }

    .masthead-right {
      @extend %flex-center;
      margin-left: auto;
    }

    .ugg-u-logo {
      margin-left: 20px;
      width: 20px;
    }

    .hamburger-icon {
      width: 16px;

      &:hover {
        cursor: pointer;

        g,
        path {
          fill: #ffffff;
        }
      }
    }

    .masthead_blue-man {
      background: #3273fa;
      border-radius: 2px;
      height: 28px;
      width: 28px;
      position: relative;
      padding: 5px;
      margin-left: 12px;
      display: grid;
      place-items: center;

      &.masthead_blue-man-premium {
        background: transparent;
      }

      &:hover {
        background-color: #1154df;
        cursor: pointer;
      }
    }

    .masthead-search-bar-container {
      @extend %flex-center;
      flex: 1;
      padding: 0 12px;

      .masthead-ugg-search-bar {
        flex: 1;
        position: relative;
        display: flex;
        align-items: center;
        height: 36px;
        min-width: 230px;
        max-width: 500px;
        border-radius: 3px;
        background-color: #0d0d28;

        @include responsive("MOBILE_LARGE") {
          max-width: 420px;
        }

        @include responsive("MOBILE_MEDIUM") {
          display: none;
        }

        ::placeholder {
          color: #8a8ebb;
          opacity: 1;
        }

        .react-autosuggest__container {
          position: static;
          width: 100%;
          height: 100%;

          &.react-autosuggest__container_placeholder {
            background-color: #0d0d28;
            border-radius: 3px;
          }

          .react-autosuggest__input {
            background-color: #0d0d28;
            width: 100%;
            height: 100%;
            color: #a9acd1;
            font-weight: 500;
            padding-left: 12px;
            padding-right: 34px;
            border-radius: 3px;
            font-size: 13px;
            filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
          }

          .react-autosuggest__suggestions-container--open {
            color: black;
            width: auto;
            top: 44px;
            left: 0;
            right: 0;
            border: none;
            font-size: 14px;
            border-radius: 3px;
          }
        }

        .react-autosuggest__container--open {
          height: 100%;
        }

        .autosuggest-button {
          position: relative;
          fill: white;
          width: 40px;
          height: 100%;
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex: none;

          svg {
            width: 16px;
          }
        }

        .react-autosuggest__container--open ~ .autosuggest-button {
          border-bottom-right-radius: 0;
        }

        .spinthatshit-loader {
          position: absolute;
          right: 24px;
          width: 56px;
          transform: scale(0.4);
        }
      }
    }
  }
}

.left-nav-container {
  display: flex;
  align-items: center;
  width: 178px;
  margin-left: -25px;

  .logo-inner {
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.masthead_account-buttons {
  display: flex;
  align-items: center;

  .login-btn {
    margin-left: 18px;
  }
}

;@import "sass-embedded-legacy-load-done:33";