.user-verification-bar {
  position: fixed;
  top: 0;
  z-index: 99999;
  width: 100vw;
  height: 40px;
  font-size: 12px;

  .user-verification-message {
    @extend %flex-center;
    width: 100%;
    height: 100%;

    &.unverified {
      background-color: #4f4f60;
    }

    &.valid-verification {
      background-color: $ugg-light-green;
    }

    &.invalid-verification {
      background-color: $ugg-red;
    }

    .resend-link {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .close-icon {
    position: absolute;
    top: 50%;
    right: 30px;
    width: 15px;
    transform: translateY(-50%);

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}

;@import "sass-embedded-legacy-load-done:37";