.content-section {
  padding: 18px;
  border-radius: 3px;
  background-color: #191937;

  &_placeholder {
  }

  @include responsive("MOBILE_LARGE") {
    padding: 18px;
  }
  @include responsive("MOBILE_SMALL") {
    padding: 12px;
  }

  &.content-section_unset {
    padding: unset;
    border-radius: unset;
    background-color: unset;
  }

  &.content-section_no-padding {
    padding: 0;
  }

  .content-section_content {
    padding: 18px;
    background-color: #191937;

    @include responsive("MOBILE_LARGE") {
      padding: 18px;
    }
    @include responsive("MOBILE_SMALL") {
      padding: 12px;
    }
  }

  .content-section_header {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    padding-left: 14px;
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;

    @include responsive("MOBILE_LARGE") {
      margin-bottom: 18px;
    }
    @include responsive("MOBILE_LARGE") {
      margin-bottom: 12px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 2px;
      height: 20px;
      border-radius: 2px;
      background-color: #3273fa;
    }
  }

  .content-section_blurb {
    color: #5f5f7b;
    font-size: 14px;
    font-weight: 400;
  }

  // Common content section elements
  .wr-matches {
    white-space: nowrap;
    .matches {
      color: #cddcfe;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

;@import "sass-embedded-legacy-load-done:15";