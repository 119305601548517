.expanded-match-card-container {
  width: 100%;
  background-color: #191937;
  border-radius: 0 0 3px 3px;
  overflow: hidden;

  &_loading {
    min-height: 550px;
  }

  .loader {
    margin-top: 24px;
  }
  // .match_gold & {
  //   .shard-active,
  //   .perk-active:not(.keystone) {
  //     border: 2px solid #ff9b00 !important;
  //   }
  //   .no-skill-up {
  //     background-color: #976019 !important;
  //   }
  //   .skill-up {
  //     background-color: #ff9b00 !important;
  //   }

  //   .content-section_header {
  //     &::before {
  //       background-color: #ff9b00 !important;
  //     }
  //   }
  // }
  // .match_win & {
  //   .shard-active,
  //   .perk-active:not(.keystone) {
  //     border: 2px solid #3273fa !important;
  //   }

  //   .no-skill-up {
  //     background-color: #223b80 !important;
  //   }

  //   .skill-up {
  //     background-color: #3273fa !important;
  //   }

  //   .content-section_header {
  //     &::before {
  //       background-color: #3273fa !important;
  //     }
  //   }
  // }
  // .match_lose & {
  //   .shard-active,
  //   .perk-active:not(.keystone) {
  //     border: 2px solid #ff4e50 !important;
  //   }

  //   .no-skill-up {
  //     background-color: #53263e !important;
  //   }

  //   .skill-up {
  //     background-color: #ff4e50 !important;
  //   }
  //   .content-section_header {
  //     &::before {
  //       background-color: #ff4e50 !important;
  //     }
  //   }
  // }

  // .match_remake & {
  //   .shard-active .perk-active:not(.keystone) {
  //     border: 2px solid #3c3c70 !important;
  //   }

  //   .no-skill-up {
  //     background-color: #25254b !important;
  //   }

  //   .skill-up {
  //     background-color: #3c3c70 !important;
  //   }

  //   .content-section_header {
  //     &::before {
  //       background-color: #3c3c70 !important;
  //     }
  //   }
  // }

  .expanded-match-card_nav {
    display: flex;
    padding: 12px 0;
    border-top: 1px solid #11112a;
    border-bottom: 1px solid #11112a;

    @include responsive("MOBILE_MEDIUM") {
      padding: 9px 0px;
      overflow-x: auto;
    }

    .tabs {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      gap: 12px;
      padding: 0px 12px;

      @include responsive("MOBILE_MEDIUM") {
        justify-content: flex-start;
      }
    }

    .nav-btn {
      @include flex-center;
      flex: 1;
      height: 36px;
      border-radius: 3px;
      background-color: #191937;
      color: #cddcfe;
      font-size: 12px;
      font-weight: 700;

      @include responsive("MOBILE_MEDIUM") {
        height: 27px;
        padding-left: 5px;
        padding-right: 5px;
      }

      .match_gold & {
        background-color: #976019;
      }
      // .match_win & {
      //   background-color: #223B80;
      // }
      // .match_lose & {
      //   background-color: #53263E;
      // }
      // .match_remake & {
      //   background-color: #25254B;
      // }
      &:hover {
        color: white;
        background-color: #313160;
        cursor: pointer;
      }

      &.active {
        background-color: #313160;
        color: white;

        .match_gold & {
          background-color: #ff9b00;
        }
        // .match_win & {
        //   background-color: #3273fa;
        // }
        // .match_lose & {
        //   background-color: #FF4E50;
        // }
        // .match_remake & {
        //   background-color: #3C3C70;
        // }
        &:hover {
          //background-color: #417DFA;
        }
      }
    }

    .full-match {
      margin-left: 12px;
      padding: 0 24px;

      @include responsive("MOBILE_SMALL") {
        flex: 1;
      }
    }
  }

  .scoring-container {
    &_loading {
      margin-top: 12px;
    }

    .subscores-container {
      .subscores-subsection {
        height: 185px;

        &:not(:first-child) {
          margin-top: 12px;
        }
      }
    }
  }

  .build-section {
    width: 100%;

    .section {
      width: 100%;
      padding: 18px;

      &:not(:last-child) {
        border-bottom: 1px solid #414165;
      }

      .build-content {
        display: flex;
        max-width: 564px;

        &_center {
          margin: 0 auto;
        }
      }

      &.runes-section {
        .primary-tree {
          margin-right: 36px;

          @include responsive("MOBILE_MEDIUM") {
            margin-right: 14px;
          }
        }
      }
      &.skill-section {
        .build-content_center {
          // overflow: auto;
        }
      }
    }

    .rune-section-header,
    .skill-section-header,
    .item-section-header {
      // margin-left: 18px;
    }
  }

  .role-img {
    @include flex-center;
    margin-bottom: 8px;
    width: 16px;
    height: 16px;
    background-color: black;

    svg {
      flex: 0 0 12px;
      width: 12px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:58";