.Feedback-Icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  width: 28px;
  height: 28px;
  border-radius: 3px;

  &:hover {
    background-color: #25254b;
    cursor: pointer;
  }
}

;@import "sass-embedded-legacy-load-done:35";