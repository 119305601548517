$content-padding: 24px;

.router-container {
  &.verification-bar-active {
    margin-top: $user-verification-bar-height;
    // margin-top: $masthead-height + $user-verification-bar-height;
  }
}

#page-content {
  display: flex;
  // padding-bottom: 48px;
  // padding-bottom: 100px; // because of footer ads

  .no-padding & {
    padding: 0;
  }

  .landing-page.verification-bar-active & {
    background-position: 0px 0px;
  }
}

#main-content {
  color: var(--font-color-main);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
}

#content-wrapper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 64px;

  @include responsive("TABLET") {
    padding-left: 0px;
  }
}

#content {
  display: flex;
  margin: 0 auto;
  z-index: 100;
  width: 100%;
  min-height: 100vh;
}

.content-side-padding {
  padding-left: 24px;
  padding-right: 24px;

  @include responsive("TABLET") {
    padding-left: 12px;
    padding-right: 12px;
  }

  @include responsive("MOBILE_MEDIUM") {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.content-top-padding {
  margin-top: 24px;
}

;@import "sass-embedded-legacy-load-done:32";