.disabled-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 12px;
  width: 100%;

  strong {
    font-size: 36px;
    font-size: 700;
  }

  .message {
    margin-top: 12px;
    font-size: 16px;
    text-align: center;
  }

  img {
    margin-top: 24px;
    width: 100%;
    max-width: 330px;
  }
}

;@import "sass-embedded-legacy-load-done:26";