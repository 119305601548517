.mac-app-notification {
  @keyframes mac-app-notification-fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 100%;
    }
  }

  .popup {
    position: fixed;
    bottom: 24px;
    right: 24px;
    z-index: 999999;
    padding: 16px 34px 12px 50px;
    height: 131px;
    width: 284px;
    border-radius: 3px;
    background-color: #ffffff;
    font-family: "Inter";
    animation: mac-app-notification-fade 0.5s;
  }

  .overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999998;
    background-color: rgba(0, 0, 0, 0.3);
    animation: mac-app-notification-fade 0.5s;
  }

  .apple-icon {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 24px;

    g,
    path {
      fill: #3273fa;
    }
  }

  .close-icon {
    position: absolute;
    top: 14px;
    right: 7px;
    padding: 5px;
    width: 10px;
    box-sizing: content-box;

    &:hover {
      cursor: pointer;
    }

    g,
    path {
      fill: #5f5f7b;
    }
  }

  strong {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #28283e;
  }

  span {
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: #5f5f7b;
  }
}

;@import "sass-embedded-legacy-load-done:31";