#terms-of-service {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;

  a {
    margin-left: 5px;
  }
}

@media (max-width: 600px) {
  #terms-of-service {
    margin: 16px !important;
    font-size: 16px;
  }
}

;@import "sass-embedded-legacy-load-done:40";