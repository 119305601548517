.champion-skill-with-label {
  position: relative;

  .skill-label {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #383864;
    font-size: 9px;
    font-weight: 700;
    line-height: 1;
    pointer-events: none;

    &.bottom-right {
      bottom: 0;
      right: 0;
      transform: translate(30%, 30%);
    }
    &.bottom-center {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      border: 2px solid #17172e;
      box-sizing: content-box;
    }
  }
}

;@import "sass-embedded-legacy-load-done:20";