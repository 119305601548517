.landing-page-container_mobile {
  position: relative;
  text-align: center;
  color: white;

  .content-side-padding-region {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .all-landing-page-content {
    // position: absolute;
    width: 100%;
    // top: 0;

    .landing-background {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -4;
      height: 100vh;

      > .overlay {
        position: absolute;
        z-index: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: radial-gradient(133.49% 100% at 50% 0%, rgba(7, 7, 32, 0.5) 0%, #070720 100%);
      }

      > video {
        position: absolute;
        z-index: -4;
        min-width: 1920px;
        width: 100%;
      }

      .background-image {
        background-image: url("https://static.bigbrain.gg/assets/lol/backgrounds/battle-bunny-aurora.png");
        background-size: cover;
        background-position: center;
        width: 100vw;
      }
    }

    .landing-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;

      .header {
        width: 60%;
        font-family: "Barlow";
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        font-size: 21px;
        text-align: center;
        text-transform: uppercase;
        margin-top: 20px;
        margin-bottom: 25px;
      }

      .ugg-logo {
        height: 50px;
        margin-top: 32px;
        cursor: pointer;
      }

      .container1 {
        position: relative;
        text-align: center;
        color: white;
        width: 100%;
        height: auto;

        .background-gradient {
          background: linear-gradient(179.86deg, rgba(7, 7, 32, 0) 0.12%, #070720 87.13%),
            linear-gradient(227.82deg, rgba(7, 7, 32, 0) 7.35%, #070720 92.47%);
          position: absolute;
          bottom: 8px;
          left: 16px;
          width: 100%;
        }
      }
    }
  }

  .testimonial {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    height: 300px;
    background-color: $ugg-landing-blue;
    overflow: hidden;

    > * {
      z-index: 1;
    }

    .quote {
      margin: 34px 0 24px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }

    .author {
      font-size: 12px;
    }
  }

  .game-switcher {
    display: flex;
    gap: 12px;
    padding-top: 36px;
    position: relative;
    flex-wrap: wrap;
    justify-content: flex-start;

    @include responsive("MOBILE_SMALL") {
      justify-content: center;
    }

    #lol {
      a {
        background-color: #191937;
        svg {
          // height: 20px;
          // width: 20px;
        }
        &:hover {
          background-color: #25254b;
        }
      }
    }

    #wow {
      &:hover {
        svg {
          path {
            fill: #ff9b00;
          }
        }
      }
    }
    #val {
      &:hover {
        svg {
          path {
            fill: #ff4655;
          }
        }
      }
    }

    .switch-icon-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .game-switch-icon {
      @include flex-center;
      width: 60px;
      height: 60px;
      background-color: #070720;

      &.active,
      svg {
        width: 32px;
        height: 32px;
      }

      border-radius: 3px;
    }

    .new-icon {
      @include flex-center;
      width: 32px;
      height: 16px;
      margin-top: 6px;
      background-color: #3273fa;
      font-weight: 700;
      font-size: 10px;
      color: #ffffff;
      border-radius: 2px;
      padding: 2px 4px;
      line-height: 12.1px;
    }

    .soon-icon {
      @include flex-center;
      height: 16px;
      margin-top: 6px;
      background-color: #25254b;
      font-weight: 700;
      font-size: 10px;
      color: #cddcfe;
      border-radius: 2px;
      padding: 2px 4px;
      line-height: 12.1px;
    }

    .beta-icon {
      @include flex-center;
      height: 16px;
      margin-top: 6px;
      background-color: #24a87b;
      font-weight: 700;
      font-size: 10px;
      color: #ffffff;
      border-radius: 2px;
      padding: 2px 4px;
      line-height: 12.1px;
    }
  }
}

.landing-page-container_mobile {
  flex: 1;
  padding-bottom: 24px;
  width: 100%;

  .autosuggest-container {
    display: flex;
    align-items: center;
    position: relative;
    height: 60px;
    width: calc(100vw - 24px * 2);
    margin-left: calc(-5vw + 24px);
    border-radius: 3px;

    .landing-region-selector {
      position: absolute;
      z-index: 1;
    }

    .react-autosuggest__container {
      flex: 1;
      height: 100%;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      &.react-autosuggest__container_placeholder {
        background-color: #ffffff;
      }
    }

    input {
      width: 100%;
      height: 100%;
      padding-left: 16px;
      font-size: 16px;
      border-radius: 5px;
      border: none;
      color: black;
      background-color: white;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 400;
    }

    .autosuggest-button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 60px;
      border-radius: 0 3px 3px 0;
      background-color: #ffffff;

      svg {
        height: 24px;
        width: 24px;

        g,
        path {
          fill: #626696;
        }
      }
    }

    .react-autosuggest__suggestions-container {
      position: relative;
      left: 0;
      right: 0;
      z-index: 100;
      width: calc(100% + 46px);
      color: #4e4f60;
      background-color: #ffffff;
      margin-top: 12px;

      .react-autosuggest__section-container {
        font-size: 12px;
      }

      // .react-autosuggest__section-container--first {
      //   margin-top: 0px;
      //   padding-top: 5px;
      // }

      ul {
        padding-left: 0;

        li {
          padding: 4px 8px;
          list-style: none;

          &:hover {
            // &.react-autosuggest__suggestion--highlighted {
            background-color: #e3e3eb;
          }

          .render-suggestion {
            position: relative;
            display: flex;
            align-items: center;
            // padding: 10px 0;

            .suggest-text {
              margin-left: 10px;
            }

            .page-type {
              position: absolute;
              top: 2px;
              left: -8px;
            }
          }
        }
      }
    }

    ::placeholder {
      color: #8a8ebb;
    }
  }

  .all-region-dropdown {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    height: 258px;
    overflow: scroll;
    margin-top: 12px;
    margin-left: calc(-5vw + 24px);
    width: calc(100vw - 48px);

    .region-header {
      background-color: #f4f7ff;
      height: 28px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      color: #383864;
      text-align: left;
      line-height: 28px;

      span {
        margin-left: 8px;
      }
    }

    .indv-region-selector {
      display: flex;
      align-items: center;
      min-height: 28px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      color: #5f5f7b;
    }
    .indv-region-selector:hover {
      background: #e7eeff;
      cursor: pointer;
    }
  }
}

;@import "sass-embedded-legacy-load-done:68";