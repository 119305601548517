@use "sass:map";

@mixin side-nav_mobile {
  &#side-nav-container {
    content: "mobile";
  }

  .side-nav_head {
    display: none;
  }

  .side-nav_footer {
    .footer_menu-container {
      .footer_menu {
        left: unset;
        transform: unset;
        right: -9px;
      }
    }
  }

  &.side-nav_expanded {
    .side-nav {
      width: 100vw;
    }
  }

  &.side-nav_collapsed {
    .side-nav {
      width: 0;
      overflow: hidden;
    }
  }
}

@mixin side-nav-breakpoint($class, $max-width: "") {
  #side-nav-container {
    &.#{$class}\:side-nav_mobile {
      $found-width: map.get($side-nav-breakpoints, $class);
      @if $found-width {
        @media (max-width: $found-width) {
          @content;
        }
      } @else {
        @media (max-width: $max-width) {
          @content;
        }
      }
    }
  }
}

$side-nav-breakpoints: (
  "xxxs": 400px,
  "xxs": 600px,
  "xs": 900px,
  "sm": 1140px,
  "md": 1360px,
  "lg": 1470px,
  "xl": 9999999px,
);

// Default breakpoints
@each $breakpoint, $width in $side-nav-breakpoints {
  @include side-nav-breakpoint($breakpoint, $width) {
    @include side-nav_mobile;
  }
}

;@import "sass-embedded-legacy-load-done:94";